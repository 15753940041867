import React, { Fragment } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { TableRow, TableCell, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { isMobile, isTablet } from 'react-device-detect';
import { fieldDateFormat, displayDateFormatShort, backendDateFormat } from '../../../constants';
import { getCurrencyFormat } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const ProjectionEvent = ({eventsInfo, eventsInfoSimulated, label, index, simulationType, onFieldChange, financeError,updateEventsErrorField, assemblyType, checkYr, generalInfo}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>#{index+1}</TableCell>
      <TableCell style={isMobile ? {maxWidth:'auto'}:{maxWidth:'120px'}}>
        { simulationType === '1' ? <h4>{moment(eventsInfo[label].eventDate).format(displayDateFormatShort)}</h4>: null }
        { simulationType === '2' ?
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               required
               margin="none"
               id="eventDate"
               format={fieldDateFormat}
               minDate={generalInfo && generalInfo.dateOfManufacture ? moment(generalInfo.dateOfManufacture) : moment().subtract(50, 'years')}
               error={financeError && financeError[label] && financeError[label].eventDate ? true : false}
               helperText={financeError && financeError[label] && financeError[label].eventDate ? financeError[label].eventDate : ''}
               onFocus={(e) => financeError && financeError[label] && financeError[label].eventDate ?  updateEventsErrorField(assemblyType, 'eventDate','',checkYr, label ) : e.preventDefault()}
               onOpen={(e) => financeError && financeError[label] && financeError[label].eventDate && updateEventsErrorField(assemblyType, 'eventDate','',checkYr, label ) }
               onAccept={(e) => financeError && financeError[label] && financeError[label].eventDate &&  updateEventsErrorField(assemblyType, 'eventDate','',checkYr, label )}
               fullWidth
               InputLabelProps={{shrink: true}}
               value={eventsInfo[label].eventDate ? eventsInfo[label].eventDate:null}
               onChange={(data, value) => {onFieldChange(value, 'eventDate', data ? moment(data).format(backendDateFormat):data, label);}}
             />
            </MuiPickersUtilsProvider>
          : null
        }
        { simulationType === '3' ?
          <Fragment>
            { eventsInfo[label].eventDate === eventsInfoSimulated[label].eventDate ?
              <h4>{moment(eventsInfo[label].eventDate).format(displayDateFormatShort)}</h4>:
              <div className="differentiation-values">
                <h4>{moment(eventsInfoSimulated[label].eventDate).format(displayDateFormatShort)}</h4>
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  {moment(eventsInfo[label].eventDate).format(displayDateFormatShort)}
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>
              </div>
            }
          </Fragment>:null
        }
      </TableCell>
      <TableCell>
        {
          simulationType == '1' ? eventsInfo[label]?.rest_days ? eventsInfo[label]?.rest_days : '0' :
          simulationType == '2' ?
          <TextField
          style={{marginTop:'0px'}}
          id={`rest_days`}
          fullWidth
          margin="normal"
          value={ eventsInfo[label].rest_days ? eventsInfo[label].rest_days : ''}
          InputLabelProps={{ shrink: true }}
          onChange={(data, value) => { regexConstants.onlyNumeric.test(data.target.value) ? onFieldChange(data, 'rest_days', parseInt(data.target.value), label) : data.preventDefault() }}
          />
          :
          simulationType =='3'? 
          eventsInfoSimulated[label]?.rest_days ?
          <>
          {eventsInfoSimulated[label].rest_days }
          { eventsInfo[label].rest_days !== eventsInfoSimulated[label].rest_days ?
            <div className="differentiation-values">
            <p style={{color:'#ff0000', fontSize:'12px'}}>
             {eventsInfo[label].rest_days}
              <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
            </p>
          </div> : null }
          </>  
          : '0' : null    
        }
      </TableCell>
      <TableCell>
        { simulationType === '1' || simulationType === '2' ? <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].mr}</NumericLabel></h4>: null }
        { simulationType === '3' ?
          <Fragment>
            { eventsInfo[label].mr === eventsInfoSimulated[label].mr ?
              <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].mr}</NumericLabel></h4>:
              <div className="differentiation-values">
                <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfoSimulated[label].mr}</NumericLabel></h4>
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].mr}</NumericLabel>
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>
              </div>
            }
          </Fragment>
          :null
        }
      </TableCell>
      <TableCell style={{maxWidth:'120px'}}>
        { simulationType === '1'  ? <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].eventCost}</NumericLabel></h4>: null }
        { simulationType === '2' ?
          <TextField
            id="eventCost"
            fullWidth
            margin="none"
            value={eventsInfo[label].eventCost ? eventsInfo[label].eventCost:''}
            error={financeError && financeError[label].eventCost ? true : false}
            onFocus={(e) => financeError && financeError[label] && financeError[label].eventCost ?  updateEventsErrorField(assemblyType,'eventCost','', checkYr, label) : e.preventDefault()}
            helperText={financeError && financeError[label].eventCost ? financeError[label].eventCost : ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'eventCost', e.target.value, label): e.preventDefault()}}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
          />
          : null
        }
        { simulationType === '3' ?
          <Fragment>
            { eventsInfo[label].eventCost === eventsInfoSimulated[label].eventCost ?
              <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].eventCost}</NumericLabel></h4>:
              <div className="differentiation-values">
                <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfoSimulated[label].eventCost}</NumericLabel></h4>
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].eventCost}</NumericLabel>
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>
              </div>
            }
          </Fragment>
          :null
        }
      </TableCell>
      <TableCell>
        { simulationType === '1' || simulationType === '2' ? <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].fundCollected}</NumericLabel></h4> : null }
        { simulationType === '3' ?
          <Fragment>
            { eventsInfo[label].fundCollected === eventsInfoSimulated[label].fundCollected ?
              <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].fundCollected}</NumericLabel></h4>:
              <div className="differentiation-values">
                <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfoSimulated[label].fundCollected}</NumericLabel></h4>
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].fundCollected}</NumericLabel>
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>
              </div>
            }
          </Fragment>
          :null
        }
      </TableCell>
      <TableCell style={isTablet || isMobile ? {width:'100px'}:{}}>
        { simulationType === '1' || simulationType === '2' ? <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].surPlus}</NumericLabel></h4>: null }
        { simulationType === '3' ?
          <Fragment>
            { eventsInfo[label].surPlus === eventsInfoSimulated[label].surPlus ?
              <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].surPlus}</NumericLabel></h4>:
              <div className="differentiation-values">
                <h4><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfoSimulated[label].surPlus}</NumericLabel></h4>
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{eventsInfo[label].surPlus}</NumericLabel>
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>
              </div>
            }

          </Fragment>
          :null
        }
      </TableCell>
    </TableRow>
  )
}
export default ProjectionEvent;
