import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const PieChartComp = ({ data, title, callExternalFunction }) => {
// var colors = ['white', green];
  const options = {
    chart: {
      type: 'pie',
      options3d: {
          enabled: true,
          alpha: 25
      },
  },
  title: {
      text: '',
  },
  // colors:colors,
  plotOptions: {
      pie: {
          innerSize: 100,
          depth: 60,
          dataLabels: {
              enabled: true,
              softConnector: false
          },
          showInLegend: false,
          allowPointSelect: true,
          cursor: 'pointer',
          animation: false,
          point: {
            events: {
              click: function( item , index) {
                callExternalFunction({item: item?.point?.name});
              }
            }
          }
      }
  },
  tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>US${point.y}</b> <br/>'
  },
  series: [{
      name:'Count',
      data: data,
      colorByPoint: true,
      dataLabels: {
        formatter: function () {
            return this.point.name + ':</b> ' +
                '(US$'+this.y+')';
        }
    }
  }
],
  credits: false,
  }
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default PieChartComp;
