import React, { useEffect, useState } from 'react'
import { Button, Grid, Paper, Tab, Table, TableBody, TableRow, TableCell, Tabs, TextField } from '@material-ui/core';
import { globalGetService, globalPostExportService, globalPostService } from '../../../utils/globalApiServices';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PieChartComp from './PieChartComp';
import { EmptyCollection, PageLoader, TableListComp } from '../../../shared_elements';
import { assetTypes, fleetGraphHd } from '..';
import NumericLabel from 'react-pretty-numbers';
import { Skeleton } from '@material-ui/lab';
import { STableLoader } from '../../../shared_elements/loaders';
import moment from 'moment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { downloadFileType, getCurrencyFormat } from '../../../utils';
import DetailedGraphList from './DetailedGraphList';
import GraphTableList from './GraphTableList';

export default function FleetPortfolioGraphs({ module, portfolio_slug }) {
    const [mrGraphData, setMrGraphData] = useState({})
    const [skeletonLoader, setSkeletonLoader] = useState(false)
    const [listLoader, setListLoader] = useState(false)
    const [isLoading, setPageLoader] = useState(false)
    const [listData, setListData] = useState([])
    const [exportQuery, setExportQuery] = useState({})
    const [svCost, setSvcost] = useState(false)
    const [graphDetailedData, setGraphDetailedData] = useState({})
    const [graphListData, setGraphListData] = useState({})
    const [filter, setFilter] = useState({ upcoming_n_sv: { label: '10', value: 10 }, asset: null, })
    useEffect(() => {
        getMrGraphDataFn()
        getListDataFn()
    }, [])
    const getMrGraphDataFn = () => {
        setSkeletonLoader(true)
        globalPostService(`new-mr-calculator/analytics/${module}/pie_charts/`, { portfolio_slug: portfolio_slug })
            .then(response => {
                setSkeletonLoader(false)
                setMrGraphData(response.data.data)
            })
    }
    const getListDataFn = () => {
        let queryParam = {
            upcoming_n_sv: filter?.upcoming_n_sv?.value,
            asset: filter?.asset?.value,
            portfolio_slug: portfolio_slug
        }
        setListLoader(true)
        globalGetService(`new-mr-calculator/fleet-calendar-view/`, queryParam)
            .then(response => {
                setListLoader(false)
                setListData(response.data.data)
            })
    }
    const callExternalFunction = (data) => {
        setPageLoader(true)
        if(data?.item ){
            setExportQuery({...data, portfolio_slug: portfolio_slug, detailed_list: true })
        }
        globalPostService(`new-mr-calculator/analytics/${module}/pie_charts/`, {...data, portfolio_slug: portfolio_slug, detailed_list: true })
        .then(response => {
                setPageLoader(false)
                setSkeletonLoader(false)
                setGraphDetailedData({data:response.data.data, flag:true, type:data.item})
            })
    }
    const applyFilter = (key, value) => {
        setFilter({ ...filter, [key]: value })
    }
    let listCount = [];
    for (let i = 1; i <= 20; i++) {
        listCount.push({ label: i.toString(), value: i })
    }
    const submitFilter = () => {
        getListDataFn()
    }
    const getGraphListFn = (data) => {
        if(data?.sv_cost){
            setSvcost(true)
        }
        setPageLoader(true)
        globalPostService(`new-mr-calculator/analytics/list/`, data)
        .then(response => {
            setPageLoader(false)
            setSkeletonLoader(false)
            setGraphListData({data:response.data.data, flag:true, name: data?.sv_cost ? 'SV Cost': 'MR Rate',  })
        })
    }
    const exportGraphListFn = (data) => {
        let queryParam =Object.assign({},data)
        queryParam={
            ...queryParam,
            total_mr_rate: svCost ? null: true,
            total_sv_cost: svCost ? true: null
        }
        delete queryParam['name']
        setPageLoader(true)
        globalPostExportService(`new-mr-calculator/analytics/list/`, {...queryParam, download:true})
        .then(response => {
            setPageLoader(false)
            downloadFileType(response.data, (`${data?.name} Export`), 'xlsx');
        })
    }
    return (
        <div>
            {
                isLoading ? <PageLoader /> : null
            }
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    {
                        skeletonLoader ?
                            <Skeleton variant="rect" animation='wave' height={400} /> :
                            <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                                <div className='events-bar-chart-title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h4>MR Rate</h4>
                                    <span style={{ cursor: 'pointer', height: '18px' }} onClick={() => { getGraphListFn({data:mrGraphData?.mr_rate, mr_rate:true}) }} >
                                        <OpenInNewIcon color='primary' style={{ fontSize: '20px' }} />
                                    </span>
                                </div>
                                <PieChartComp
                                    callExternalFunction={(data)=>callExternalFunction({...data,mr_rate:true})}
                                    data={mrGraphData?.mr_rate}
                                />
                            </Paper>
                    }
                </Grid>
                <Grid item xs={12} lg={6}>
                    {
                        skeletonLoader ?
                            <Skeleton variant="rect" animation='wave' height={400} /> :
                            <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                                <div className='events-bar-chart-title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h4>SV Cost</h4>
                                    <span style={{ cursor: 'pointer', height: '18px' }} onClick={() => { getGraphListFn({data:mrGraphData?.sv_cost, sv_cost:true}) }} >
                                        <OpenInNewIcon color='primary' style={{ fontSize: '20px' }} />
                                    </span>
                                </div>
                                <PieChartComp
                                    callExternalFunction={(data)=>callExternalFunction({...data,sv_cost:true})}
                                    data={mrGraphData?.sv_cost}
                                />
                            </Paper>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Paper className='events-bar-chart'>
                        <div className='fleet-title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h4>Upcoming SV Events</h4>
                        </div>
                        <Grid container spacing={1} style={{padding:'2px', display: 'inline-flex', alignItems: 'center'}} >
                             
                                {/* <Paper style={{ padding: "10px" }}> */}
                                    {/* <Grid style={{ display: 'inline-flex', alignItems: 'center' }}> */}
                                        <Grid  item lg={3} xs={12}>
                                            <Autocomplete
                                                options={listCount}
                                                fullWidth
                                                disableClearable={true}
                                                getOptionLabel={option => option.label}
                                                value={filter?.upcoming_n_sv || null}
                                                getOptionSelected={(option, val) => option.value == val.value}
                                                // style={{ width: 150 }}
                                                id="upcoming_n_sv"
                                                onChange={(e, value) => applyFilter('upcoming_n_sv', value)}
                                                renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true' }} label="Number of Rows" placeholder="" variant="outlined" />}
                                            />
                                        </Grid>
                                        <Grid item lg={3} xs={12} >
                                            <Autocomplete
                                                options={assetTypes}
                                                fullWidth
                                                getOptionLabel={option => option.label}
                                                value={filter?.asset || null}
                                                getOptionSelected={(option, val) => option.value == val.value}
                                                // style={{ width: 150 }}
                                                id="asset"
                                                onChange={(e, value) => applyFilter('asset', value)}
                                                renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true' }} label="Asset" placeholder="" variant="outlined" />}
                                            />
                                        </Grid>
                                        <Grid item lg={2} xs={12}>
                                            <Button fullWidth variant='contained' color='primary' size='small' onClick={() => submitFilter(filter)} >
                                                Apply
                                            </Button>
                                        </Grid>
                                    {/* </Grid> */}
                                {/* </Paper> */}
                    
                            <Grid item xs={12}>
                                {
                                    listLoader ?
                                        <STableLoader count={3} /> :
                                        <TableListComp
                                            heads={fleetGraphHd}
                                            data={listData?.list_data?.map(item => (
                                                <TableRow >
                                                    <TableCell>
                                                        <h4>{item?.asset.toUpperCase() || '--'}</h4>
                                                    </TableCell>
                                                    <TableCell>{item?.serial_number}</TableCell>
                                                    <TableCell>{moment(item?.nsv_date).format('MMM DD, YYYY') || '--'}</TableCell>
                                                    <TableCell>{item?.check_details || '--'}</TableCell>
                                                    <TableCell>{<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr_rate ? item?.mr_rate : 0}</NumericLabel>}</TableCell>
                                                    <TableCell>{<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.sv_cost ? item?.sv_cost : 0}</NumericLabel>}</TableCell>
                                                    <TableCell>{<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.fund_balance ? item?.fund_balance : 0}</NumericLabel>}</TableCell>
                                                </TableRow>
                                            ))}
                                            noRecord={listData?.list_data?.length ? null : <EmptyCollection title="No Data Found!" />}
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {
                graphDetailedData?.flag ?
                <DetailedGraphList
                callExternalFunction={callExternalFunction}
                exportQuery={exportQuery} 
                module={module}
                closeModal={()=>setGraphDetailedData({flag:false})}
                setGraphDetailedData={setGraphDetailedData}
                graphDetailedData={graphDetailedData}
                /> : null
            }
            {graphListData?.flag &&
                <GraphTableList 
                exportGraphListFn={exportGraphListFn}
                setGraphListData={setGraphListData}
                graphList={graphListData}
                />
            }
        </div>
    )
}
