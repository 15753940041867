import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, TableRow, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid } from '@material-ui/core';
import { TableListComp, PageLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { globalGetService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
const regex = /^[0-9]*$/;
class LlpPopupModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      modal:false,
      engineNo:'',
      customCycleRemain:null,
      originalList:null,
      wrongEntry:'',
      llpLimits:[],
      enginesEdit:true

    };
  }
  onFieldChange = (e, keyParam, value, index) => {
    const { customCycleRemain } = this.state;
    let editCycles = false
    if(value != customCycleRemain[index].csn){
      editCycles = true;
    }
    if(value != customCycleRemain[index].max_limit){
      editCycles = true
    }
    if(value != customCycleRemain[index].remaining){
      editCycles = true
    }else {
      editCycles = false
    }
    switch (keyParam){
      case 'csn':
        this.setState(prevState => ({
          ...prevState,
          customCycleRemain:{
            ...prevState.customCycleRemain,
            [index]:{
              ...prevState.customCycleRemain[index],
              [keyParam]: value ? parseInt(value) : 0,
              remaining: prevState.customCycleRemain[index].max_limit - value,
              editCycles:editCycles
            }
          },
        }))
        break;
      case 'max_limit':
      if(!value){
        this.setState(prevState => ({
          ...prevState,
          customCycleRemain:{
            ...prevState.customCycleRemain,
            [index]:{
              ...prevState.customCycleRemain[index],
              [keyParam]:value ? parseInt(value) : 0,
              remaining:0,
              csn:0,
              editCycles:editCycles
            }
          },
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          customCycleRemain:{
            ...prevState.customCycleRemain,
            [index]:{
              ...prevState.customCycleRemain[index],
              [keyParam]:value ? parseInt(value) : 0,
              remaining:value - prevState.customCycleRemain[index].csn,
              editCycles:editCycles
            }
          },
        }))
      }

        break;
      case 'remaining':
        this.setState(prevState => ({
          ...prevState,
          customCycleRemain:{
            ...prevState.customCycleRemain,
            [index]:{
              ...prevState.customCycleRemain[index],
              [keyParam]:value ? parseInt(value) : 0,
              csn:prevState.customCycleRemain[index].max_limit - value,
              editCycles:editCycles
            }
          },
        }))
        break;
      default:
    }
  }
  compareCustomCycleRemainingFn = () => {
    const {customCycleRemain} = this.state
    let llpLimitsArr = Object.keys(customCycleRemain).map(item=> customCycleRemain[item]).filter(item => item?.editCycles == true)
    this.props.setFormData({...this.props.formData, llpLimits:llpLimitsArr})
    setTimeout(()=>{
      this.setState({modal:false})
      this.props.onAddEditAssembly()
    },200)
  
  }
  updateLLpLimits = (index) => {
    this.setState(prevState => ({
      ...prevState,
      llpLimits:[...prevState.llpLimits, index]
    }))
  }
  disableFields = (flag) => {
    let disableFlag = false
    if(flag){
      return disableFlag = false
    }
    if(!this.props.lsvDate){
      if(flag) {
        return disableFlag = false
      }else {
        return disableFlag = true
      }
    }
  }
  customCycleRemainApi=( engineTypeId, engineNo, mrLogDetail)=> {
    const {formData}= this.props
    globalGetService(`mr-calculator/engine-type/${formData?.id}/llps/?log_id=${formData?.log_maintenance}&engine_no=0&llp_limits=true`)
      .then(response => {
        if (checkApiStatus(response)) {
            let fullList = response.data.data?.llp_list 
            let editedLLp = response.data.data?.llp_limits 
            let combinedList =  {...fullList,...editedLLp }
            combinedList = Object.keys(combinedList).map(label=> ({...combinedList[label], csn:( parseInt(combinedList[label]?.max_limit) -parseInt(combinedList[label]?.remaining)) }))
          this.setState({
            modal: true,
            customCycleRemain: combinedList,
          })
        } 
        else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        this.setState({ pageLoader: false });
      })
  }
  toggleEditFn = ()=>{
    this.setState({modal:false})
  }
  viewLlpFn = ()=>{
    this.setState({modal:false})
  }
  render(){
    const { modal, customCycleRemain, wrongEntry, pageLoader,llpLimits, enginesEdit } = this.state;
    const {formData}= this.props
    return(
      <Fragment>
       { !modal ?
          <span
            style={{color:'#3f51b5', textDecoration:'underline', cursor:'pointer', fontSize:'14px', paddingLeft:'4px'}}
            onClick={() => this.customCycleRemainApi()}
          >
            { 'Edit LLPs' }
          </span>
          :
            <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
            fullScreen
          >
            <DialogTitle id="scroll-dialog-title">
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  {enginesEdit ? 'Update':''} Life Limited Parts
                  <p style={{fontSize:'13px', color:'#8b8d91'}}>These values will be used to set the starting point of an LLPs Life during operation</p>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                {  
                    !enginesEdit ?
                    <Button color="primary" variant="contained" style={{float:'right'}} onClick={this.toggleEditFn} >Edit LLP</Button>
                    :<Button color="primary" variant="outlined" style={{float:'right'}} onClick={this.viewLlpFn} >View LLP</Button>
                  }
                </Grid> */}
              </Grid>
            </DialogTitle>
            <DialogContent dividers={true}>
              <div>
                <TableListComp
                  size={'small'}
                  heads={[{label:'Description', sortOption:false},{label:'CSN', sortOption:false},  {label:'Cycle Limit', sortOption:false}, {label:'Cycles Remaining', sortOption:false}, {label:'Life (%) Remaining', sortOption:false}]}
                  data={Object.keys(customCycleRemain).map((label, index) =>{
                      let disabledFlag = true
                      if(formData?.lsv_date){
                        disabledFlag = false
                      }else {
                        if(customCycleRemain[label].on_wing_replaceable){
                          disabledFlag = false
                        }
                      }
                    return (
                      <TableRow hover tabIndex={-1}>
                        <TableCell style={{width:'120px'}}>
                          {customCycleRemain[label].description} <br/>
                        { enginesEdit && disabledFlag ? <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>Please update Last Shop Visit to edit</span> : null}
                        </TableCell>
                        <TableCell style={{width:'120px'}}>
                          { enginesEdit ?
                            <TextField
                              id={`csn_${index}`}
                              fullWidth
                              margin="normal"
                              value={customCycleRemain[label].csn}
                              InputLabelProps={{shrink: true}}
                              disabled={disabledFlag}
                              inputProps={{ maxLength: 6 }}
                              onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) && e.target.value <= customCycleRemain[label].max_limit ? this.onFieldChange(e, 'csn', e.target.value, label):e.preventDefault()}}
                              onBlur={() => this.updateLLpLimits(label) }
                            />:
                            customCycleRemain[label].csn
                          }
                        </TableCell>
                        <TableCell style={{width:'120px'}}>
                          { enginesEdit ?
                            <TextField
                              id={`max_limit_${index}`}
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              value={customCycleRemain[label].max_limit}
                              InputLabelProps={{shrink: true}}
                              disabled={disabledFlag}
                              onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'max_limit', e.target.value, label):e.preventDefault()}}
                              onBlur={() => this.updateLLpLimits(label)}
                            />:
                            customCycleRemain[label].max_limit
                          }
                        </TableCell>
                        <TableCell style={{width:'120px'}}>
                          { enginesEdit ?
                            <TextField
                              id={`remaining_${index}`}
                              fullWidth
                              margin="normal"
                              disabled={disabledFlag}
                              inputProps={{ maxLength: 10 }}
                              value={customCycleRemain[label].remaining}
                              InputLabelProps={{shrink: true}}
                              onBlur={() => this.updateLLpLimits(label) }
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) && e.target.value <= customCycleRemain[label].max_limit ?  this.onFieldChange(e, 'remaining', e.target.value, label):e.preventDefault()}}
                            />:
                            customCycleRemain[label].remaining
                          }
                        </TableCell>
                        <TableCell style={{width:'120px'}}>{ customCycleRemain[label].remaining && customCycleRemain[label].max_limit ?  (customCycleRemain[label].remaining/customCycleRemain[label].max_limit*100).toFixed(2) : 0}</TableCell>
                      </TableRow>
                    )
                  }

                  )}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false})}>Cancel</Button>
              { enginesEdit ?
                <Button color="primary" variant="contained" onClick={() => this.compareCustomCycleRemainingFn()}>Save</Button>:null
              }
            </DialogActions>
          </Dialog>
        } 
        { pageLoader ? <PageLoader />:null }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(LlpPopupModal));
