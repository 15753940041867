import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Table, TableBody, Paper, Tabs, Tab, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, Link, CircularProgress } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { FleetHeader, FleetList, ImportFleet } from './';
import { FilterComponent, Pagination, EmptyCollection, PageLoader } from '../../../shared_elements'
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { globalExportService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../../utils';
import { fleetFilters } from '../'
const AddPortfolio = ({history, addEditPortFolio, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState(addEditPortFolio.data);
    const [error, setError] = useState({});
    const [files, setFiles] = useState([]);
    const handleDropzoneChange = (files) => {
        setFiles(files);
    }
    const getSampleTemplate = () => {
        globalExportService(`new-mr-calculator/sample-import-sheet/`)
        .then(response => {
            downloadFileType(response.data, 'Sample_Import_Report', 'xlsx');
        })
    }
    const onFieldChange = (key, value) => {
        setFormData({...formData, [key]:value});
    }
    const onAddEditPortfolio = () => {
        let validationInputs = {
            name:formData.name ?'':'Please enter Portfolio Name'
        };
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true)
            if(formData.id){
                globalPutService(`new-mr-calculator/fleet/portfolio/initiate/`, formData)
                .then(response => {
                    if(checkApiStatus(response)){
                        if (files){
                            let importFormData = new FormData();
                            importFormData.append('import_file', files[0]);
                            importFormData.append('module_type', 'portfolio');
                            importFormData.append('portfolio_slug', response.data.data.slug);
                            globalPostService('new-mr-calculator/fleet-portfolio-import/', importFormData)
                            .then(import_response => {
                                setLoading(false)
                                if(checkApiStatus(import_response)){
                                    setFiles([]);
                                    getResponseBack()
                                    enqueueSnackbar(import_response?.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                                }else{
                                    enqueueSnackbar(import_response?.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                                }
                            })
                        }else{
                            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        }
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                })
            }else{
                let payload = {
                    ...formData,
                }
                globalPostService(`new-mr-calculator/fleet/portfolio/initiate/`, payload)
                .then(response => {
                    if(checkApiStatus(response)){
                        if (files) {
                            let importFormData = new FormData();
                            importFormData.append('import_file', files[0]);
                            importFormData.append('module_type', 'portfolio');
                            importFormData.append('portfolio_slug', response.data.data.slug);
                            globalPostService('new-mr-calculator/fleet-portfolio-import/', importFormData)
                            .then(import_response => {
                                if(checkApiStatus(import_response)){
                                    setFiles([]);
                                    getResponseBack()
                                    enqueueSnackbar(import_response?.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                                }else{
                                    enqueueSnackbar(import_response?.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                                }
                            })
                        }else{
                            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        }
                        toggleModalFn();getResponseBack();
                        // history.push('/dashboard')
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                })
            }
            
        }else{
            setError(validationInputs);
        }
    }
    let filterOptions = Object.assign({}, fleetFilters);
    return(
        <>
            <Dialog
                open={addEditPortFolio.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth={'sm'}
            >
                <DialogTitle id="scroll-dialog-title">
                    {addEditPortFolio.data.id ? 'Edit' :'Add'} Portfolio
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField 
                                required
                                id='name'
                                label='Name'
                                value={formData?.name||''}
                                fullWidth
                                margin="normal"
                                error={error?.name?true:false}
                                helperText={error?.name||''}
                                onChange={(e) => onFieldChange('name', e.target.value)}
                                onFocus={() => setError({...error, 'name':''})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField 
                                id='remarks'
                                label='Remarks'
                                inputProps={{ maxLength: 225}}
                                value={formData?.remarks||''}
                                fullWidth
                                margin="normal"
                                multiline={true}
                                rows={4}
                                error={error?.remarks?true:false}
                                helperText={error?.remarks||''}
                                onChange={(e) => onFieldChange('remarks', e.target.value)}
                                onFocus={() => setError({...error, 'remarks':''})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <p>Refer the Sample Template - <Link component="button" variant="body2" onClick={getSampleTemplate}>Download</Link></p>
                            <div style={{width:'100%'}}>
                                <DropzoneArea
                                    filesLimit={1}
                                    maxFileSize={104857600}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={true}
                                    acceptedFiles={['.xlsx']}
                                    showPreviews={ true }
                                    dropzoneText={ <p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                    dropzoneClass="drag-drop-cnt"
                                    maxWidth="sm"
                                    showAlerts={['info','error']}
                                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                    clearOnUnmount={true}
                                    onChange={handleDropzoneChange} 
                                />
                            </div>
                        </Grid>
                    </Grid>
                    
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onAddEditPortfolio} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={20} /> :'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            { isLoading ? <PageLoader/> : null }
        </>
    )
}
export default withRouter(AddPortfolio);