import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Paper, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, Table, TableBody } from '@material-ui/core';
import { FleetHeader, FleetList } from '../components';
import { FilterComponent, Pagination, PageLoader, EmptyCollection } from '../../../shared_elements'
import { STableLoader } from '../../../shared_elements/loaders';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus, removeEmptyKey } from '../../../utils';
import { fleetFilters } from '../'
const LinkPortfolioAssembly = ({match, tabIndex, linkAssembly, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [assemblyInfo, setAssemblyInfo] = useState({list:[], pagination:{}});
    const [selectedAssets, setSelectedAssets] = useState([])
    useEffect(() => {
        getAssemblyInfo({...filter, asset:tabIndex}, 'skeletonLoader');
    },[]);
    const getAssemblyInfo = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) :setLoading(true);
        globalGetService(`new-mr-calculator/fleet-portfolio-list/`, {...query, module_type:'fleet-level', exclude_portfolio_slug:match.params.slug})
        .then(response => {
            if(checkApiStatus(response)){
                setAssemblyInfo(response.data.data)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false) :setLoading(false);
        })
    }
    const onAssetSelection = (flag, id) => {
        if (flag) {
            setSelectedAssets([...selectedAssets, id])
        } else {
            setSelectedAssets(selectedAssets.filter(item => item !== id))
        }
    }
    const onLinkAssembly = () => {
        setLoading(true);
        globalPostService(`new-mr-calculator/portfolio-list/${match.params.slug}/link/`, {asset:tabIndex,id:selectedAssets})
        .then(response => {
            if(checkApiStatus(response)){
                toggleModalFn(); getResponseBack();
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    return(
        <>
            <Dialog
                open={linkAssembly.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth='lg'
            >
                <DialogTitle id="scroll-dialog-title">
                    Link Assembly
                </DialogTitle>
                <DialogContent dividers={true}>
                    {skeletonLoader ? <STableLoader count={9}/> :
                        <Paper>
                            <Table className='mui-styled-table'>
                                <FleetHeader tabIndex={tabIndex}/>
                                <TableBody>
                                    {assemblyInfo.list.map((item, itemIndex) =>
                                        <FleetList 
                                            item={item}
                                            tabIndex={tabIndex}
                                            selectedAssets={selectedAssets}
                                            onAssetSelection = {onAssetSelection}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                            <Pagination 
                                pagination={assemblyInfo.pagination}
                                onChangePage={(event, newPage) => getAssemblyInfo({page:newPage+1, per_page: assemblyInfo.pagination.per_page, asset:tabIndex}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getAssemblyInfo({page:1, per_page: event.target.value, asset:tabIndex}, 'pageLoader')}
                            />
                            {assemblyInfo.list.length ? null:<div style={{textAlign:'center'}}><EmptyCollection title='No records found'/></div>}
                        </Paper>
                    }
                    
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    {selectedAssets.length ?
                        <Button disabled={isLoading} onClick={onLinkAssembly} color='primary' size='small' variant='contained'>
                            {isLoading ? <CircularProgress size={24}/>:'Save'}
                        </Button>:null
                    }
                </DialogActions>
            </Dialog>
            { isLoading ? <PageLoader/>:null}
        </>
    )
}
export default withRouter(LinkPortfolioAssembly);