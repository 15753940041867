import React, { Fragment } from 'react';
import config from '../../config';
import { Link } from "@material-ui/core" ;
import { getLocalStorageInfo, onCheckPermission } from '../../utils';
import settingsIcon from '../../assets/images/setting.svg';
import homeIcon from '../../assets/images/home_icon.png';
import reviewDockIcon from '../../assets/images/review_dock.png';
import analyticsIcon from '../../assets/images/report_icon.png';
import fleetIcon from '../../assets/images/fleet_icon.svg';
import portFolioIcon from '../../assets/images/portfolio_icon.svg';
export default function MainNavLink(){
  let analyticsViewer = getLocalStorageInfo().defaultLessor?.commverge_universal_analytics === true
    if(getLocalStorageInfo()) {
      return(
        <Fragment>
          <ul className="list-unstyled" style={{marginTop:'20px'}}>
            <li>
              <a href={config.domain.subDomian} target="_blank" rel="noreferrer">
                <img src={homeIcon} alt="Home"/>
                <p>Home</p>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled" style={{paddingBottom:'80px',marginTop:'16px'}}>
            <li className={window.location.href.includes('initiate') || window.location.href.includes('logs') || window.location.href.includes('intermediate') || window.location.href.includes('financial-plots')  ? 'active':'' } >
              <Link href="/logs">
                <img src={`${config.api.s3ImageUrl}mr_cal_icon.png`} alt="CommVerge" />
                <p>Logs</p>
              </Link>
            </li>
            {
             onCheckPermission('mr_calculator','fleet_data','R')?
             <li className={window.location.href.includes('fleets') ? 'active':'' } >
              <Link href="/fleets">
                <img src={fleetIcon} alt="Fleet" />
                <p>Fleet</p>
              </Link>
            </li>
            : null}
            {
             onCheckPermission('mr_calculator','portfolio','R')?
            <li className={window.location.href.includes('portfolio') ? 'active':'' } >
              <Link href="/portfolios">
                <img src={portFolioIcon} alt="Portfolios" />
                <p>Portfolios</p>
              </Link>
            </li>
            : null}
            {
              analyticsViewer ?
              <li className={window.location.href.includes('analytics-graph') ? 'active':''} >
              <Link  href="/analytics-graph">
                <img src={analyticsIcon} alt="Analytics" />
                <p>Analytics</p>
              </Link>
            </li>
             : null}
            <li className={window.location.href.includes('audit-log') ? 'active':''} >
              <Link  href="/audit-log">
                <img src={reviewDockIcon} alt="Review Dock" />
                <p>Audit Log</p>
              </Link>
            </li>
            { getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id === 242 ?
        getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups && getLocalStorageInfo().user.security_groups.filter(group => group.id === 29).length ?
        <li className={window.location.href.includes('settings') ? 'active':''}>
          {/* <Link href={`/settings/users`}> */}
          <Link href={`${config.domain.subDomian}users/manage`} target='_blank'>
            <img src={settingsIcon} alt="users" />
            <p>Settings</p>
          </Link>
        </li>:null:<li className={window.location.href.includes('settings') ? 'active':''}>
            {/* <Link href={`/settings/users`}> */}
            <Link href={`${config.domain.subDomian}users/manage`} target='_blank'>
              <img src={settingsIcon} alt="users" />
              <p>Settings</p>
            </Link>
          </li>
      }
          </ul>
          </Fragment>
      )
    }else {
      return null
    }

}
