import React, { Fragment, useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Drawer, Button, CircularProgress } from '@material-ui/core';
import { MentionsInput, Mention } from 'react-mentions'
import Comment from './Comment';
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import CommentIcon from '@material-ui/icons/Comment';
import CloseIcon from '@material-ui/icons/Close';
const Comments = ({match}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentObj, setComment] = useState({comment:''});
    const [userList, setUserList] = useState([]);
    const getUserList = () => {
        globalGetService('new-mr-calculator/userlist-commverge/',{application:4}).then((response) => {
            if(checkApiStatus(response)){
                setUserList(response.data.data);
            }
        })
    }
    const getComments = () => {
        globalGetService('new-mr-calculator/comments/',{logid:match.params.id}).then((response) => {
            if(checkApiStatus(response)){
                setComments(response.data.data);
                setOpen(true);
                let element = document?.getElementById("end-of-comments");
                element.scrollIntoView({block: "end"});
            }
        })
    }
    const addEditComment = (e) => {
        e.preventDefault();
        setLoading(true);
        globalPostService('new-mr-calculator/comments/', {log:match.params.id, comment:commentObj.comment}).then((response) => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setComment({comment:''})
                getComments();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
        
    }

    return(
        <Fragment>
            <CommentIcon onClick={() => {getComments();getUserList()}} color="primary" />
            { isOpen ?
                <Drawer anchor={'right'} open={true} onClose={() => setOpen(false)}>
                    <div className='comments-wrapper'>
                        <div className='comments-header'>
                            <div className="flex-centered">
                                <h2>Comments</h2>
                                <CloseIcon fontSize='small' onClick={() => {setOpen(false); setComments([]); setComment({})}} style={{marginLeft:'auto', cursor:'pointer'}} />
                            </div>
                        </div>
                        <div className='comments-body' style={{height:`${window.innerHeight - 212}px`, overflow:'auto'}}>
                            {comments.map((item,index) => 
                                <div className='comment-card'>
                                    <Comment users={userList} item={item} getResponseBack={() => getComments()} />
                                </div>
                            )}
                            <div id="end-of-comments" />
                        </div>
                        <div className='comments-footer'>
                            <form onSubmit={addEditComment}>
                                <div>
                                    <MentionsInput 
                                        className="comment-input" 
                                        placeholder="Type @ to mention someone" 
                                        style={{minHeight:'100px'}} 
                                        value={commentObj.comment} onChange={(event, newValue, newPlainTextValue, mentions) => setComment({...commentObj, comment:newValue})}
                                        allowSuggestionsAboveCursor={true}
                                        forceSuggestionsAboveCursor={true}
                                        maxLength={500}
                                    >
                                        <Mention
                                            trigger="@"
                                            data={userList.map(item => {return{id:item.email, display:item.name}})}
                                            
                                        />
                                    </MentionsInput>
                                    
                                </div>
                                <Button disabled={isLoading || commentObj?.comment?.trim().length === 0} type="submit" variant="contained" color="primary" size='small'>
                                    {isLoading ? <CircularProgress size={'small'} />:'Submit'}
                                </Button>  
                            </form>
                        </div>
                    </div>
                </Drawer>:null
            }
        </Fragment>
    )
}
export default withRouter(Comments);