import React, { Fragment, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Paper, Tooltip, Button, TextField, InputAdornment } from '@material-ui/core';
import { regexConstants } from '../../../constants/regEx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getLocalStorageInfo, getCurrencyFormat } from '../../../utils'
const EditEngineType = ({mrLogDetail, isLocked, workScope, updateEngineTypeFn, getResponseBack, engineTypes, getEngineTypeApi, onEngineTypeChange, engine }) => {
    const [engTypeEdit, setEngType] = useState(false);
    let enginetype = mrLogDetail?.generalInfo?.engineType
    return (
        <Fragment>
            {engTypeEdit == false ?
                <Fragment>
                    <div>
                        <span style={workScope.life_limited_by_llp ? { color: '#f44337' } : {}}>
                            {workScope?.edited_engine_type?.name ? workScope?.edited_engine_type?.name : workScope?.engine_type?.name ?workScope?.engine_type?.name : enginetype?.name || '--'}
                            {getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                                <Tooltip title="Edit" placement="top" arrow><EditIcon style={{ cursor: 'pointer' }} color="primary" fontSize="small" className="sv-edit-icon" onClick={() => { setEngType(engTypeEdit ? false : true); getEngineTypeApi(workScope?.edited_engine_type?.id ? workScope?.edited_engine_type?.id : workScope?.engine_type?.id ? workScope?.engine_type?.id : enginetype?.id) }} /></Tooltip> : null
                            }
                        </span>
                    </div>
                    {workScope.life_limited_by_llp ? <span className="restricted-llp" style={{ fontSize: '11px', background: '#fff1b6', padding: '2px' }}> {workScope.life_limited_by_llp} </span> : null}
                </Fragment> :
                <Fragment>
                    <div style={{display:'inline-flex'}}>
                    <Autocomplete
                        options={engineTypes}
                        getOptionLabel={option => option.name}
                        id="asset_type"
                        // disabled={addEditAssembly.mode === 'edit' || isfetchingList ? true : false}
                        value={workScope?.edited_engine_type ? workScope?.edited_engine_type : workScope?.engine_type ? workScope?.engine_type : enginetype || null}
                        onChange={(e, value) => { onEngineTypeChange(value) }}
                        renderInput={params => <TextField required {...params} placeholder={"Select Engine Type"} margin="none" style={{ width: '150px', verticalAlign: 'baseline', }} fullWidth InputLabelProps={{ shrink: true }} />}
                    />
                    <span style={{margin:'4px 0px 0px 5px'}}>
                    <span>
                        <Tooltip title="Cancel" placement="top" arrow>
                            <CancelIcon onClick={() => { getResponseBack(); setEngType(engTypeEdit ? false : true) }} style={{ color: '#f50057', cursor: 'pointer', height: '20px' }} />
                        </Tooltip>
                    </span>
                    <span onClick={() => { updateEngineTypeFn(engine); setEngType(engTypeEdit ? false : true) }}>
                        <Tooltip title="Save" placement="top" arrow>
                            <DoneIcon style={{ color: '#3f51b5', marginLeft: '5px', cursor: 'pointer', height: '20px' }} />
                        </Tooltip>
                    </span>
                    </span>
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}
export default EditEngineType;