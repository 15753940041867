import React from 'react'
import { TableRow, TableCell, TextField, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Delete } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import { regexConstants } from '../../../constants/regEx';
import { onCheckPermission } from '../../../utils';
export default function EOLConditionList({error, eol_conditionList, onFocusEolConidtion, onDeleteEOLContent, itemObj, itemIndex, lease, leaseIndex, onFieldChangeEolCndtn, pltConstants, eol_scenarios, editPermission}) {
  const selectAssembly = lease.eol_conditions.map(item => item.component_name && item.component_name.name ? item.component_name.name : '');
  const assemblyList = Object.assign([], eol_conditionList);
  return (
        <TableRow>
            <TableCell style={{ width: '320px' }}>
              <Autocomplete
                className='input-option'
                options = {assemblyList.filter(item => !selectAssembly.includes(item.name))}
                getOptionLabel={option => option.name}
                id={`component_name_${itemIndex}`}
                value={itemObj.component_name}
                disabled={editPermission? false:true}
                onFocus={()=>onFocusEolConidtion(leaseIndex,itemIndex, 'component_name' )}
                onChange={(e, value) => {onFieldChangeEolCndtn(leaseIndex , itemIndex, 'component_name', value);}}
                renderInput={params => <TextField required  {...params} error={error.component_name?true:false} helperText={error.component_name? error.component_name: null}  margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
              />
            </TableCell>
            <TableCell style={{ width: '320px' }}>
            <Autocomplete
              className='input-option'
              options = {pltConstants.filter(item => item.type == "eol_mint_condition")}
              getOptionLabel={option => option.label}
              id={`conditions_${itemIndex}`}
              disabled={editPermission? false:true}
              value={itemObj.expected_mint_condition}
              onFocus={()=>onFocusEolConidtion(leaseIndex,itemIndex, 'expected_mint_condition' )}
              onChange={(e, value) => {onFieldChangeEolCndtn(leaseIndex , itemIndex, 'expected_mint_condition', value);}}
              renderInput={params => <TextField required  {...params} error={error.expected_mint_condition?true:false} helperText={error.expected_mint_condition} margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
              />
            </TableCell>
            <TableCell style={{ width: '320px' }}>
          <TextField
            className='input-number-filed'
            id={`value_${itemIndex}`}
            fullWidth
            margin="normal"
            value={itemObj.value? itemObj.value: ''}
            variant="outlined"
            disabled={editPermission && itemObj?.expected_mint_condition?.value == 3 ? false: true}
            error={error.value?true:false} 
            helperText={error.value}
            InputProps ={{endAdornment:
              <InputAdornment position="end">
             { itemObj?.component_name?.value_type
            }</InputAdornment>
            }}
            onFocus={()=>onFocusEolConidtion(leaseIndex,itemIndex, 'value' )}
            onChange={(e, value) => regexConstants.onlyNumeric.test(e.target.value) ?onFieldChangeEolCndtn(leaseIndex , itemIndex, 'value', e.target.value): e.preventDefault()}
            InputLabelProps={{ shrink: true }}
          />
            </TableCell>
            <TableCell style={{textAlign:'right'}}>
              {
                onCheckPermission('mr_calculator','analyze_mr_cash_flow','D' ) ?
                <Delete variant='contained' color='secondary' style={{ cursor:'pointer'}} onClick={()=>onDeleteEOLContent(leaseIndex , itemIndex)} />
              : null}
            </TableCell>
        </TableRow>
    )
}
