
import React, { useState } from 'react'
import { DialogTitle, TableRow, TableCell, Dialog, DialogContent, DialogActions, Button, Accordion } from '@material-ui/core';
import { PageLoader, TableListComp } from '../../../shared_elements';
import { globalExportService, globalPostExportService } from '../../../utils/globalApiServices';
import { downloadFileType } from '../../../utils';

export default function DetailedGraphList({ graphDetailedData, exportQuery, module, closeModal, callExternalFunction, graphType, eventData }) {
  let analyticsListDetailedData = graphDetailedData?.data
  const [pageLoader, setLoading] = useState(false)
  var titleName = ''
  const exportListFn = () => {
    let url = ''
    url = `new-mr-calculator/analytics/${module}/pie_charts/`
    setLoading(true)
    globalPostExportService(url, { ...graphDetailedData?.data, ...exportQuery, download: true })
      .then(response => {
        setLoading(false)
        downloadFileType(response.data, (`${titleName} Export`), 'xlsx');
      })
  }

  titleName = `${graphDetailedData?.type} Graph`
  return (
    <div>
      {
        pageLoader ? <PageLoader /> : null
      }
      <Dialog
        open='true'
        onClose={closeModal}
        maxWidth='lg'
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: "space-between" }}>
            {titleName}
            <span>
              <Button onClick={exportListFn} variant='outlined' color='primary' size='small' style={{ float: "right" }}>
                Export
              </Button>
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <TableListComp
            heads={analyticsListDetailedData?.columns.map(item => { return { label: item, value: item } })}
            data={
              Object.keys(analyticsListDetailedData?.list_data).map(index =>
                <TableRow style={{ cursor: 'pointer' }} onClick={() => window.open(`intermediate/${analyticsListDetailedData?.log_ids[index]}`, '_blank')}>
                  {
                    analyticsListDetailedData?.list_data[index].map((childItem, childIndex) =>
                      <TableCell>
                        {childItem}
                      </TableCell>
                    )
                  }
                </TableRow>
              )}
            pagination={analyticsListDetailedData.pagination}
            onChangePage={(event, newPage) => callExternalFunction( {...exportQuery, page: newPage + 1, per_page: analyticsListDetailedData.pagination.per_page })}
            onChangeRowsPerPage={(event) => callExternalFunction({...exportQuery, page: 1, per_page: event.target.value },)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant='contained' color='primary' size='small' style={{ float: "right" }}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
