import React, { Fragment, useEffect, useState } from 'react'
import { Paper, Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, Tooltip, TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { GoogleLocationField, PageLoader } from '../../../shared_elements';
import RegionCustomVariable from './RegionCustomVariable';
import EditIcon from '@material-ui/icons/Edit';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils';
import { useSnackbar } from 'notistack';
export default function EditRegionModal({ data, mrLogDetail, onClose, isLocked, workScope, wkIndex, updateRegionFn }) {
    const [modal, setModal] = useState(false)
    const [isLoading, setLoader] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [positionDropDown, setPositionDropDown] = useState([]);
    const [regionData, setRegionData] = useState({});
    const [regionCondition, setRegionCondition] = useState({});
    const [prevRegionCondition, setPrevRegionCondition] = useState({});
    const [adjustRegionVar, setAdjustRegionVar] = useState(false);
    const [engTypeEdit, setEngType] = useState(false);
    let payload = {
        engine_type: workScope?.edited_engine_type?.id ? workScope?.edited_engine_type?.id : workScope?.edited_engine_type ? workScope?.edited_engine_type : workScope?.engine_type?.id ? workScope?.engine_type?.id : workScope?.engine_type ? workScope?.engine_type : mrLogDetail?.generalInfo?.engineType?.id,
        region_id: workScope?.edited_region_of_operation?.id ? workScope?.edited_region_of_operation?.id : workScope?.edited_region_of_operation ? workScope?.edited_region_of_operation : workScope?.region_of_operation ? workScope?.region_of_operation : mrLogDetail?.generalInfo?.regionOfOperation?.id,
        assign_default: workScope?.edited_variable_condition ? workScope?.edited_variable_condition : workScope?.variable_condition || null
    }
    const onClickEdit = () => {
        setRegionData({ ...regionData, location: workScope?.edited_location?.address ? workScope?.edited_location : workScope?.location ? workScope.location : mrLogDetail?.location, variable_condition: workScope?.edited_variable_condition ? workScope?.edited_variable_condition : workScope?.variable_condition })
        getAssetPositionDrpdwn()
        getRegionVariables(payload)

    }
    const getAssetPositionDrpdwn = () => {
        let query = { constant_types: ['engine_position'] }
        globalPostService(`console/get-constants/`, query)
            .then(response => {
                setPositionDropDown(response.data.data)
            })
    }
    const resetRegionVariables = () => {
        if (adjustRegionVar) {
            setAdjustRegionVar(false);
            setRegionCondition(prevRegionCondition)
        } else {
            setPrevRegionCondition(regionCondition)
            setAdjustRegionVar(true);
        }
    }
    const onChangeRegionCond = (value, index) => {
        if (adjustRegionVar) {
            setRegionCondition({
                ...regionCondition,
                variable_condition: {
                    ...regionCondition.variable_condition,
                    [index]: {
                        ...regionCondition.variable_condition[index],
                        default: value
                    }
                }
            })
        }
    }
    const updateRegionData = () => {
        updateRegionFn(regionData, setModal)
    }
    const updateRegionVariables = () => {
        let variable_condition = [];
        Object.keys(regionCondition?.variable_condition).map((label) => {
            if (regionCondition?.variable_condition[label].default) {
                variable_condition.push(regionCondition?.variable_condition[label]?.default);
            }
        });
        setRegionData({ ...regionData, variable_condition: variable_condition })
        setAdjustRegionVar(false);
        enqueueSnackbar('Variable got stored', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    }
    const getRegionVariables = (data, regionObj) => {
        setLoader(true)
        globalPostService(`console/regions_maps/`, data)
            .then(response => {
                setLoader(false)
                if (checkApiStatus(response)) {
                    setRegionCondition(response.data.data);
                    if (regionObj) {
                        let variable_condition = [];
                        Object.keys(response.data.data?.variable_condition).map((label) => {
                            if (response.data.data?.variable_condition[label].default) {
                                variable_condition.push(response.data.data?.variable_condition[label]?.default);
                            }
                        });
                        setRegionData({ ...regionData, location: regionObj, regionOfOperation: response.data.data?.current_region, variable_condition: variable_condition })
                    }
                    setModal(true)
                }
            })
    }
    const onFieldChange = (key, value) => {
        setRegionData({ ...regionData, [key]: value })
        let regionObj = {}
        value.address_components.map(location => {
            let types = location.types[0];
            switch (types) {
                case 'country':
                    regionObj = {
                        ...regionObj,
                        country_name: location.long_name,
                        country_iso_2: location.short_name
                    }
                    return
                case "administrative_area_level_1":
                    regionObj = {
                        ...regionObj,
                        state_name: location.long_name,
                        state_code: location.short_name
                    }
                    return
                case "administrative_area_level_2":
                    regionObj = {
                        ...regionObj,
                        city_name: location.long_name,
                        city_code: location.short_name
                    }
                    return
                default:
                    return
            }
        });
        regionObj = {
            ...regionObj,
            address: `${regionObj.city_name ? regionObj.city_name : ''}${regionObj.city_name ? ', ' : ''}${regionObj.state_name ? regionObj.state_name : ''}${regionObj.state_name ? ', ' : ''}${regionObj.country_name}`
        }
        let payload = {}
        payload = {
            ...payload,
            location: regionObj,
            engine_type: workScope?.edited_engine_type?.id ? workScope?.edited_engine_type?.id : workScope?.engine_type?.id ? workScope?.engine_type?.id : null
        }
        getRegionVariables(payload, regionObj)
    }
    const toggleModalFn = () => {
        setModal(false)
    }
    return (
        <>
            {isLoading ? <PageLoader /> : null}
            {
                <Fragment>
                    <div>
                        <span>
                            {workScope?.edited_location?.address?.length > 20 ?
                                <Tooltip title={workScope?.edited_location?.address}><span>{`${workScope?.edited_location?.address.substr(0, 20)}...`}</span></Tooltip>
                                : workScope?.edited_location?.address ? workScope?.edited_location?.address :
                                    workScope?.location?.address?.length > 20 ?
                                        <Tooltip title={workScope?.location?.address}><span>{`${workScope?.location?.address.substr(0, 20)}...`}</span></Tooltip>
                                        : workScope?.location?.address ? workScope?.location?.address :
                                            mrLogDetail?.location?.address?.length > 20 ?
                                                <Tooltip title={mrLogDetail?.location?.address}><span>{`${mrLogDetail?.location?.address.substr(0, 20)}...`}</span></Tooltip> :
                                                mrLogDetail?.location?.address
                            }
                            {getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                                <Tooltip title="Edit" placement="top" arrow><EditIcon style={{ cursor: 'pointer' }} color="primary" fontSize="small" className="sv-edit-icon" onClick={() => { setEngType(engTypeEdit ? false : true); onClickEdit() }} /></Tooltip> : null
                            }
                        </span>
                    </div>

                    <Dialog
                        open={modal}
                        onClose={toggleModalFn}
                        className='console-management-modal'
                        maxWidth='md'
                    >
                        <DialogTitle style={{ width: '800px' }} id="scroll-dialog-title">
                            Edit Region of Operation
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <GoogleLocationField
                                        onFieldChange={(event, key, value) => onFieldChange('location', value)}
                                        location={regionData?.location?.address ? regionData.location.address : ''}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Paper style={{ padding: '10px' }}>
                                        <div></div>
                                        <div className="custom-region-variables">
                                            <RegionCustomVariable
                                                regionCondition={regionCondition?.variable_condition}
                                                onChangeRegionCond={onChangeRegionCond}
                                                updateRegionVariables={updateRegionVariables}
                                                adjustRegionVar={adjustRegionVar}
                                                toggleAdjustRagionVarFn={resetRegionVariables}
                                            />
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                            <Button onClick={updateRegionData} disabled={adjustRegionVar} color='primary' size='small' variant='contained'>Save</Button>
                        </DialogActions>
                    </Dialog>
                </Fragment >
            }
        </>
    )
}
