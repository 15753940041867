import React, { Fragment, useState } from 'react';
import NumericLabel from 'react-pretty-numbers';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Paper, Tooltip, Button, TextField, InputAdornment } from '@material-ui/core';
import { regexConstants } from '../../../constants/regEx';
import { getLocalStorageInfo, getCurrencyFormat } from '../../../utils'
const EngineSVCost = ({workScope, svCostType, onFieldChange, cancelUsageUpdateFn, updateSvCost, isLocked}) => {
    const [editSvCost, seteditSvCost] = useState(false);
    const editedSvCostType = 'edited_' + svCostType
    return(
        <Fragment>
            {editSvCost ?
                <ul className="list-inline mr-edit flex-centered">
                    <li className="list-inline-item" style={{width:'70%'}}>
                        {workScope[editedSvCostType] || workScope[editedSvCostType] == "" ?
                            <TextField
                                id={svCostType}
                                margin="none"
                                value={workScope[editedSvCostType]}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, e.target.value, editedSvCostType) : e.preventDefault() }}
                            /> :
                            <TextField
                                id={svCostType}
                                margin="none"
                                value={workScope[svCostType]}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, e.target.value, svCostType) : e.preventDefault() }}
                            />
                        }
                    </li>
                    { !isLocked ?
                        <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                            <CancelIcon color="secondary" className="sv-card-action" fontSize="small" onClick={() => { seteditSvCost(editSvCost ? false : true); cancelUsageUpdateFn('engines') }} />
                        </li> : null
                    }
                    { !isLocked ?
                        <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                            <DoneIcon color="primary" className="sv-card-action" fontSize="small" onClick={() => { updateSvCost(); seteditSvCost(editSvCost ? false : true) }} />
                        </li> : null
                    }
                </ul>
                :
                <Fragment>
                    {!(workScope[editedSvCostType] !== undefined && workScope[editedSvCostType] !== null) || (workScope[svCostType] == workScope[editedSvCostType]) ?
                        <div>
                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{workScope[svCostType]}</NumericLabel>
                            <span style={{ marginLeft: '4px', cursor: 'pointer' }}>
                                {workScope.nsv ?
                                    getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                                    <Tooltip title="Edit" arrow><EditIcon color="primary" fontSize="small" className="sv-edit-icon" onClick={() => seteditSvCost(editSvCost ? false : true)} /></Tooltip> : null
                                    : null
                                }
                            </span>
                        </div> 
                        :
                        <div className="differentiation-values">
                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{workScope[editedSvCostType]}</NumericLabel>
                            <span style={{ marginLeft: '4px', cursor: 'pointer' }}>
                                {!isLocked ?
                                    <Tooltip title="Edit" arrow><EditIcon color="primary" fontSize="small" className="sv-edit-icon" onClick={() => seteditSvCost(editSvCost ? false : true)} /></Tooltip> : null
                                }
                            </span>
                            <p style={{color:'#ff0000', fontSize:'12px'}}>
                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{workScope[svCostType]}</NumericLabel>
                                <Tooltip title="Original Value" arrow>
                                    <InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} />
                                </Tooltip>
                            </p>
                        </div>
                    }
                </Fragment>
            }       
        </Fragment>
    )
}
export default EngineSVCost;