import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Button, Grid, Paper } from '@material-ui/core';
import { TableListComp, PageLoader, EmptyCollection, ExportManu, FilterUIComp, DeletePopUp, } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { Log } from '../components';
import { getAPUTypesAc, getAircraftTypesAc, getEngineTypesAc, getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { removeEmptyKey, convertFilterObject, getLocalStorageInfo } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { regexConstants } from '../../../constants/regEx';
import { getMrLogListApi, exportLogInfoApi, deleteMrLogApi, updateLockApi } from '../apiServices';
import { mrLogsHd, mrCalFilterOps } from '../';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
class LogListing extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader:false,
      sort:'',
      sort_by:'',
      filter:{},
      applyingFilter:{},
      deleteModal:false,
      videoDialog: false,
      deleteIds:[],
      mrLogsInfo:{
        logs:[],
        pagination:{}
      }
    }
    this.getMrLogListApi = getMrLogListApi.bind(this);
    this.deleteMrLogApi = deleteMrLogApi.bind(this);
    this.exportLogInfoApi = exportLogInfoApi.bind(this);
    this.updateLockApi = updateLockApi.bind(this);
  }
  componentDidMount(){
    this.props.getAircraftTypes(this.props, {'mr-calculator':'mr-calculator'});
    this.props.getEngineTypes();
    this.props.getAPUTypes();
    this.props.getPlatformConstants();
    this.getMrLogListApi(this.props, {}, 'skeletonLoader');
    trackActivity('Page Visited', {page_title: 'Logs Listing'})
  }
  applyFilter = (keyParam, value) => {
    if(keyParam === 'log_id'){
      if(regexConstants.numberWithDot.test(value)){
        this.setState(prevState => ({
          ...prevState,
          applyingFilter: {
            ...prevState.applyingFilter,
            [keyParam]: value
          }
        }))
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }

  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getMrLogListApi(this.props, {...query}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter, mrLogsInfo } = this.state;
    this.setState({
      sort:sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    });
    if(sortField === sort){
      if(sort_by === 'asc'){
        this.getMrLogListApi(this.props, {...filter, sort: sortField, sort_by: 'desc', per_page:mrLogsInfo.pagination.per_page}, 'pageLoader');
      }else{
        this.getMrLogListApi(this.props, {...filter, sort: sortField, sort_by: 'asc', per_page:mrLogsInfo.pagination.per_page}, 'pageLoader');
      }
    }else{
      this.getMrLogListApi(this.props, {...filter, sort: sortField, sort_by: 'asc', per_page:mrLogsInfo.pagination.per_page}, 'pageLoader');
    }
  }
  exportReportFn = (file) => {
    const {filter} = this.state
    this.exportLogInfoApi(this.props, removeEmptyKey({...filter, download:file.extension}))
  }
  redirectToNewFinanceRpt = () => {
    this.props.history.push('/initiate')
  }
  render(){
    let filterOptions = mrCalFilterOps;
    const { mrLogsInfo, filter, applyingFilter, sort, sort_by, skeletonLoader, pageLoader, deleteModal, deleteIds } = this.state;
    filterOptions = {
      ...filterOptions,
      engine_type: {
        ...filterOptions.engine_type,
        options: this.props.engineTypes
      },
      aircraft_type: {
        ...filterOptions.aircraft_type,
        options: this.props.aircraftTypes
      },
      apu_type: {
        ...filterOptions.apu_type,
        options: this.props.apuTypes
      }
    }
    return(
      <div className="mr-calculator-section">
        <DeploymentMessage/>
        <div className="mr-logs-list">
          { skeletonLoader ? <STableLoader count={7} />:
            <Fragment>
              <Paper>
              <Grid alignItems='center' container spacing={0} className="new-finance-rpt-cta">
                <Grid item md={8} lg={9}>
                  <FilterUIComp
                    filter={filter}
                    applyingFilter={applyingFilter}
                    removeFilter={this.removeFilter}
                    removeAllFilter={() => { this.setState({ applyingFilter: {} }); this.getMrLogListApi(this.props, {}, 'pageLoader')}}
                    submitFilter={() => this.getMrLogListApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), per_page:mrLogsInfo.pagination.per_page, sort:sort, sort_by:sort_by}, 'pageLoader')}
                    applyFilter={this.applyFilter}
                    filterMenu={filterOptions}
                  />
                </Grid>
                <Grid item  md={4} lg={3}>
                  <div className="export-and-entry">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <ExportManu title="Export" files={[{title:'Excel', extension: 'xlsx', key:''}]} exportReportFn={(file) => this.exportReportFn(file) } />
                      </li>
                      {getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('C') != -1 ?
                      <li className="list-inline-item">
                        <Button size="small" color="primary" variant="contained" onClick={this.redirectToNewFinanceRpt}>New Finance Report</Button>
                      </li> : null }
                    </ul>
                  </div>
                </Grid>
              </Grid>
              </Paper>
              <TableListComp
                heads={mrLogsHd}
                sort={sort}
                sort_by={sort_by}
                data={ mrLogsInfo.logs.map((item,index) =>
                  <Log
                    item={item}
                    deleteLogFn={() => this.setState({deleteModal:true, deleteIds:[item.id]})}
                    updateLockfn={this.updateLockApi}
                    props={this.props}
                  />
                )}
                pagination={mrLogsInfo.pagination}
                onChangePage={(event, newPage) => this.getMrLogListApi(this.props, {...filter, page:newPage+1, per_page: mrLogsInfo.pagination.per_page}, 'pageLoader')}
                onChangeRowsPerPage={(event) => this.getMrLogListApi(this.props, {...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                createSortHandler={this.createSortHandler}
                noRecord={ mrLogsInfo.logs.length ? null:
                  <EmptyCollection
                    title="No records found"
                  />
                }
              />
            </Fragment>
          }
          <DeletePopUp
            modal={deleteModal}
            toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:[]})}}
            title="Delete Log"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteMrLogApi(this.props, {log_id:deleteIds})}
          />
        </div>
        { pageLoader ? <PageLoader /> : null }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  apuTypes: state.shareReducer.apuTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  engineTypes: state.shareReducer.engineTypes,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: (props, query) => dispatch(getAircraftTypesAc(props, query)),
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getAPUTypes: (query) => dispatch(getAPUTypesAc(query)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['lg_position']})),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(LogListing))
