import React, { Component, Fragment } from 'react';
import config from '../../../config';
class Login extends Component {
  constructor(props){
    window.location.href = `${config.domain.subDomian}login?apps=mrCalculator`;
  }
  render(){
    return(
      <Fragment> </Fragment>
    )
  }
}
export default Login;
