import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
export default function QuickMrModal({ onClose, quickMr, exportDataQuickMr }) {
    return (
        <Dialog
            open={quickMr?.flag}
            onClose={onClose}
            maxWidth='lg'
        >
            <DialogTitle >
                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span> Quick MR Data </span>
                    <Button size='small' variant='outlined' color='primary' onClick={exportDataQuickMr}>
                        Export
                    </Button>
                </span>
            </DialogTitle>
            <DialogContent dividers={true}>
                <div className="derate-matrix-blk-modal" style={{ marginTop: '15px', borderTop: '1px solid #cbc6c6', overflow: 'auto' }}>
                    <div style={{ overflowX: 'auto' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Ratio / Derate</th>
                                    {
                                        quickMr?.data?.loi_details[0]?.ratios.map((item, index) =>
                                            <th className="text-center">{item?.ratio}</th>
                                        )}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    quickMr?.data?.loi_details.map(mrItem =>
                                        <tr>
                                            <td className="text-center">{mrItem?.percentage}</td>
                                            {
                                                mrItem?.ratios.map((item, index) =>
                                                    <td className="text-center">${item?.mr}</td>
                                                )}
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
            <DialogActions dividers={true}>
                <Button size='small' variant='outlined' color='primary' onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
