import React,{ useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
require('highcharts/modules/exporting')(Highcharts);
const AircraftCostVTypeGraph = ({aircraftData, callExternalFunction, costGraphType, tabIndex}) => {
    const [toggle, setToggle]= useState(false)
    useEffect(()=>{
        setToggle(true)
    },[])
    let aircraftTypes = aircraftData?.aircraft_type || []
    let avgMrRate = aircraftData?.total_mr_rate || []
    let avgSvCost = aircraftData?.total_sv_cost || []
    const options = {
        chart: {
            type: costGraphType ===1 ?'column':'line',
        },
    
        title: {
            text: ''
        },
    
        subtitle: {
            text: ''
        },
    
        xAxis: {
            categories: aircraftTypes
        },
    
        yAxis: [{ // Primary axis
            className: 'highcharts-color-0',
            title: {
                text: 'Aircraft Cost (US$)'
            }
        }, { // Secondary axis
            className: 'highcharts-color-1',
            opposite: true,
            title: {
                text: 'MR Rate (US$)'
            }
        }],
    
        plotOptions: {
            column: {
                borderRadius: 5,
                point: {
                    events: {
                      click: function( item , index) {
                        tabIndex == 'generic' ? setToggle(false):
                        callExternalFunction('cost', 
                        {xData:item?.point?.category, yData:item?.point?.series?.name, value:item?.point?.y});
                      }
                    }
                  }
            },
            line: {
                point: {
                    events: {
                      click: function( item , index) {
                        tabIndex == 'generic' ? setToggle(false):
                        callExternalFunction('cost', 
                        {xData:item?.point?.category, yData:item?.point?.series?.name, value:item?.point?.y});
                      }
                    }
                  }
            }
        },
    
        series: [{
            name: 'Aircraft Cost',
            color:'#0066FF',
            data: avgSvCost,
            tooltip: {
                valuePrefix: ' US$'
            }
        }, {
            name: 'MR Rate',
            data: avgMrRate,
            color:'#F9AC2A',
            yAxis: 1,
            tooltip: {
                valueSuffix: '/ Month',
                valuePrefix: ' US$'
            }
        }],
        credits: {
            enabled: false
        },
    }
    return(
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default AircraftCostVTypeGraph;
