import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core';
import { ExportManu, PageLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { calculateDeRateApi, exportDeRateApi, loiMrApi } from '../apiServices';
import { Info } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import QuickMrModal from './QuickMrModal';
import { globalPostExportService } from '../../../utils/globalApiServices';
import { downloadFileType } from '../../../utils';

class LOICalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false,
      derate: {
        incRatio: '',
        ratios: [],
        derates: []
      },
      error: {},
      modal: false,
      quickMr:{
        flag:false,
      },
      quickMrEstimation:null
    };
    this.calculateDeRateApi = calculateDeRateApi.bind(this);
    this.exportDeRateApi = exportDeRateApi.bind(this);
    this.loiMrApi = loiMrApi.bind(this);
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }

  updateCondition = (condition) => {
    if (this.state.derate.derates.indexOf(condition.id) === -1) {
      this.setState(prevState => ({
        ...prevState,
        derate: {
          ...prevState.derate,
          derates: [...prevState.derate.derates, condition.id]
        },
        error: {
          ...prevState.error,
          derates: ''
        }
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        derate: {
          ...prevState.derate,
          derates: prevState.derate.derates.filter(id => id !== condition.id)
        }
      }));
    }
  }
  updateIncRatio = (e, val) => {
    const { engineBasicInfo } = this.props;
    let rationRange = [engineBasicInfo.ratio];
    const value = val ? parseFloat(val?.value) :e.target.value;
    let basicRatio = engineBasicInfo.ratio
    if (regexConstants.numberWithOnedigitDot.test(value) && value > 0 && value < engineBasicInfo.max_ratio) {
      while (Math.round(basicRatio * 10) / 10 >= engineBasicInfo.min_ratio) {
        basicRatio = Math.round((basicRatio - value) * 10) / 10;
        if (Math.round(basicRatio * 10) / 10 >= 0 && Math.round(basicRatio * 10) / 10 >= engineBasicInfo.min_ratio) {
          rationRange.push(parseFloat(Math.round(basicRatio * 10) / 10));
        }
      }
      basicRatio = parseFloat(engineBasicInfo.ratio);
      while (basicRatio <= engineBasicInfo.max_ratio) {
        basicRatio = Math.round((parseFloat(basicRatio) + parseFloat(value)) * 10) / 10;
        if (basicRatio >= 0 && basicRatio <= engineBasicInfo.max_ratio) {
          rationRange.push(parseFloat(basicRatio.toFixed(1)));
        }
      }
    }
    this.setState(prevState => ({
      ...prevState,
      derate: {
        ...prevState.derate,
        incRatio: val ? val:value,
        ratios: rationRange.sort(function (a, b) { return a - b }),
      },
    }));
  }
  calculateMrRates = () => {
    const { derate } = this.state;
    this.calculateDeRateApi(this.props, { ...derate }, { engine_no: this.props.engineBasicInfo.engineNo }, () => this.props.getResponseBack())
  }
  calcQuickMr =()=>{
    const { derate } = this.state;
    this.calculateDeRateApi(this.props, { ...derate, quick_mr: true, }, { engine_no: this.props.engineBasicInfo.engineNo },  () => this.props.getResponseBack())
  }

  reCalculateMrRates = async () => {
    try {
      await this.loiMrApi({ log_id: this.props.match.params.id }, { engine_no: this.props.engineBasicInfo.engineNo, recalculate: true });
      this.props.enqueueSnackbar("LOI MR calculations have started. You'll be notified through email once completed.", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      setTimeout(() => {
        this.props.getResponseBack();
      }, 1000);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
   exportDataQuickMr =(data)=>{
    let payload = {...this.state.quickMr?.data, 
      download:true,
      engine_no: this.props.engineBasicInfo.engineNo,
      quick_mr:true,
    }
    this.setState({pageLoader:true})
    globalPostExportService(`new-mr-calculator/create-loi-mr/${this.props.match?.params?.id}/`, payload)
    .then(response=>{
      this.setState({pageLoader:false})
        downloadFileType(response.data, (`Quick MR Rates Export (${this.props.match?.params?.id})`), 'xlsx');
    })
}

  render() {
    const { derate, pageLoader, error } = this.state;
    const { engineBasicInfo, deRateList, loi } = this.props;
    const loidata = loi
    let incRationOptions = []
    let i = engineBasicInfo?.min_ratio
    while (i < engineBasicInfo?.max_ratio) {
      incRationOptions.push({ label: parseFloat(i.toFixed(1)).toString(), value: parseFloat(i.toFixed(1)) });
      i = i + 0.1;
    }
    if(derate?.derates?.length && derate?.incRatio?.length){
      var estimatedTime = derate?.derates.length * derate?.incRatio?.length * 6 ;
    }
    return (
      <Fragment>
        <div className="engine-derate-modal" style={{padding:'6px'}}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                id='utilization_ratio'
                fullWidth
                label="Base Utilization Ratio"
                margin="normal"
                value={engineBasicInfo.ratio}
                InputLabelProps={{ shrink: true }}
                disabled={true}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              {
                (loidata[1] || loidata[0])?.status === 1 ? 
              <Autocomplete
                options={incRationOptions}
                getOptionLabel={option => option.label}
                id="incRatio_option"
                multiple={true}
                getOptionSelected={(option, value) => option.value === value.value}
                // value={derate.incRatio || null}
                onChange={(e, value) => this.updateIncRatio(e, value)}
                renderInput={params => <TextField {...params} error={error.incRatio ? true : false} onFocus={() => this.updateErrorField('incRatio', '')} helperText={error.incRatio ? error.incRatio : ''} required label="Utilization Ratio" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
              />:
              <TextField
                required
                id='incRatio'
                fullWidth
                label="Enter Increment Ratio"
                error={error.incRatio ? true : false}
                helperText={error.incRatio ? error.incRatio : ''}
                onFocus={() => this.updateErrorField('incRatio', '')}
                margin="normal"
                value={derate.incRatio}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => regexConstants.numberWithOnedigitDot.test(e.target.value) ? this.updateIncRatio(e) : e.preventDefault()}
                variant="outlined"
              />}
            </Grid>
            {derate.ratios.length ?
              <Grid item xs={12} sm={12} md={12}>
                <div className="loi-row">
                  <label className="label">Projected Ratio Range</label>
                  <div style={{ overflowX: 'auto' }}>
                    <Table>
                      <TableRow>
                        {derate.ratios.map((ratio, index) => 
                        <TableCell className={ratio === engineBasicInfo.ratio ? 'base-ratio' : ''} key={index}>
                            {ratio}
                          </TableCell>
                        )}
                      </TableRow>
                    </Table>
                  </div>
                </div>
              </Grid> : null
            }
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="loi-row">
                <label style={error.derates ? { color: '#f44335' } : {}}>Select De-rate value <sup>*</sup></label>
                {/* <table>
                  <tr style={{cursor:'pointer'}}>
                    {deRateList.map((deRate, index) => <td onClick={() => this.updateCondition(deRate)} className={derate.derates.indexOf(deRate.id) !== -1 ? 'selected' : ''} key={index}>{deRate.condition}</td>)}
                  </tr>
                </table> */}
                <Table>
                    <TableRow>
                      {deRateList.map((deRate, index) => (
                        <TableCell
                          key={index}
                          className={derate.derates.indexOf(deRate.id) !== -1 ? 'selected' : ''}
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.updateCondition(deRate)}
                        >
                          {deRate.condition}
                        </TableCell>
                      ))}
                    </TableRow>
                </Table>
                {error && error.derates ?
                  <span style={{ position: 'relative', color: '#f44335', top: '5px' }}>{error.derates}<br /></span> : null
                }
              </div>
            </Grid>
          </Grid>
          <div style={{ margin: '5px 0px' }}>
          <Grid container spacing={1} >
            <Grid xs={12} md={8} style={{marginTop:'3px'}}>
            <ul className='list-inline' >
              <li className='list-inline-item'>
                <Button color="primary" onClick={() => this.setState({ modal: false, deRateList: [], error: {}, derate: { incRatio: '', ratios: [], derates: [] } })}>Cancel</Button>
              </li>
              <li className='list-inline-item'>
                {loi?.length === 0 ? <Button disabled={true} color="primary" variant="contained" >Calculate MR Rates</Button> : <Button disabled={(loidata[1] || loidata[0])?.status === 1 ? true : false} color="primary" variant="contained" onClick={() => this.calculateMrRates()}>{(loidata[1] || loidata[0])?.status === 1 ? "Processing" : 'Calculate MR Rates'}</Button>}
              </li>
              {(loidata[1] || loidata[0])?.status === 2 && (loidata[1] || loidata[0])?.loi_details.length > 0 && <li className='list-inline-item' style={{ marginLeft: '10px' }}>
                <ExportManu variant="outlined" title="Export" files={[{ title: 'Excel', extension: 'xls', key: '' }]} exportReportFn={(file) => this.exportDeRateApi(this.props, { data: loi.loi_details, download: 'xlsx', engine_no: engineBasicInfo.engineNo })} />
              </li>}
            </ul>
            </Grid>
            <Grid xs={5} md={4} style={{marginTop:'3px'}}>
            <span style={{float:'right'}}>
              {(loidata[1] || loidata[0])?.status === 1 ?
                <><span className='quickmr-estimted-time'>
                  {estimatedTime ? `Estimated time : ${estimatedTime} Sec` : null }
                </span>
                  <Button color="primary" variant="contained" onClick={() => this.calcQuickMr()}>Quick MR Rates</Button></>
                : null}
            </span>
            {(loidata[0] || loidata[1])?.status === 2 && <Button color="primary" variant="contained" style={{ float: 'right' }} onClick={() => this.reCalculateMrRates()}>ReCalculate
              <Tooltip title='Recalculate to update the MR Rate based on current condition and values.' arrow placement='top'>
                <Info style={{ fontSize: '19px', marginLeft: '3px' }} />
              </Tooltip>
            </Button>
            }
            </Grid>
            </Grid>
          </div>
        </div>
        <div>
          {(loidata[1] || loidata[0])?.status === 2 ?
            <div className="derate-matrix-blk-modal" style={{ marginTop: '15px', borderTop: '1px solid #cbc6c6', overflow: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <table>
                  <thead>
                    <tr>
                      <th>Ratio / Derate</th>
                      {(loidata[1]?.loi_details && loidata[1]?.loi_details[0]?.ratios) || (loidata[0]?.loi_details && loidata[0]?.loi_details[0]?.ratios)
                        ? (loidata[1] || loidata[0])?.loi_details[0]?.ratios.map((ratio, index) => <th className="text-center" key={index}>{ratio.ratio}</th>)
                        : null
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {(loidata[1] || loidata[0])?.loi_details?.map((deRate, index) =>
                      <tr>
                        <td>{deRate.percentage}</td>
                        {deRate.ratios.map((ratio, childIndex) =>
                          <td className="text-center">${ratio.mr}</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            : (loidata[1] || loidata[0])?.status === 1 &&
            <>
              <p style={{ textAlign: 'center', fontSize: '14px' }}>Processing .....</p>
              <p style={{ textAlign: 'center', fontSize: '13px' }}>
                <span style={{ fontSize: '14px', fontWeight: 600 }}>Note:</span> <span style={{ backgroundColor: '#def581' }}>LOI Table Calculations are Under Processing It will take approximately 15 - 20 mins </span>
              </p>
            </>
          }
          {pageLoader ? <PageLoader /> : null}
        </div>
        {
          this.state?.quickMr?.flag?
          <QuickMrModal
          exportDataQuickMr={this.exportDataQuickMr}
          quickMr={this.state.quickMr}
          onClose={()=>this.setState({quickMr:{flag:false, data:{}}})}
          />
        : null}
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(LOICalModal));
