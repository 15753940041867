import React, { useState } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';
import ApuGraph from './ApuGraph'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Button } from '@material-ui/core';
import LgGraph from './LgGraph'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ApuLgFilter from './ApuLgFilter'
export default function ApuLgSection({ getGraphListFn, setApuLgFilter, apuLgfilter, lgData, apuData, lgLoader, apuLoader, getResponseBack, callExternalFunction,tabIndex }) {
  const [lgGraphType, setLgGraphType] = useState({ label: 'Bar Plot', value: 1 })
  const [apuGraphType, setApuGraphType] = useState({ label: 'Bar Plot', value: 1 })
  return (
    <div>
      <Paper className="generic-filter-block" style={{marginTop:'2px'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <ApuLgFilter
              setFilter={setApuLgFilter}
              filter={apuLgfilter}
              getResponseBack={getResponseBack}
              processingLoader={null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {apuLoader ?
              <Skeleton variant="rect" animation='wave' height={350} />
              :
              <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                <div className='events-bar-chart-title' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h4>
                    APU Graph
                  </h4>

                  {
                    apuData?.apu_type?.length ?
                      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Autocomplete
                          className='chart-type-switcher'
                          options={[{ label: 'Bar Plot', value: 1 }, { label: 'Line Plot', value: 2 }]}
                          getOptionLabel={option => option.label}
                          value={apuGraphType || null}
                          style={{ width: 135, marginRight: '20px' }}
                          disableClearable={true}
                          id="aircraft_type"
                          onChange={(e, value) => setApuGraphType(value)}
                          renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true', }} />}
                        />
                        <span style={{ cursor: 'pointer', height: '18px' }} onClick={() => { getGraphListFn(apuData, ' APU Graph') }} >
                          <OpenInNewIcon color='primary' style={{ fontSize: '20px' }} />
                        </span>
                      </span>
                      : null}
                </div>
                <ApuGraph
                tabIndex={tabIndex}
                  apuGraphType={apuGraphType?.value}
                  apuData={apuData}
                  callExternalFunction={callExternalFunction}
                />
              </Paper>
            }
          </Grid>
          <Grid item xs={12} md={6}>
            {lgLoader ?
              <Skeleton variant="rect" animation='wave' height={350} />
              :
              <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                <div className='events-bar-chart-title' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <h4> LG Graph</h4>
                  {
                    lgData?.aircraft_type?.length ?
                      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Autocomplete
                          options={[{ label: 'Bar Plot', value: 1 }, { label: 'Line Plot', value: 2 }]}
                          getOptionLabel={option => option.label}
                          value={lgGraphType || null}
                          style={{ width: 135, marginRight: '20px' }}
                          disableClearable={true}
                          id="switcher"
                          onChange={(e, value) => setLgGraphType(value)}
                          renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true', }} />}
                        />
                        <span style={{ cursor: 'pointer', height: '18px' }} onClick={() => { getGraphListFn(lgData, 'LG Graph') }} >
                          <OpenInNewIcon color='primary' style={{ fontSize: '20px' }} />
                        </span></span>
                      : null}
                </div>
                <LgGraph
                tabIndex={tabIndex}
                  lgGraphType={lgGraphType?.value}
                  callExternalFunction={callExternalFunction}
                  lgData={lgData}
                />
              </Paper>
            }
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

