import React, { Fragment, Component } from 'react';
import { MixpanelConsumer } from 'react-mixpanel';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getLocalStorageInfo, getGlobalCookie, authorizeAutoLogin, eraseGlobalCookie } from '../utils';
import { DesktopHeader, MobileHeader, DeskTopSidebar, SecondarySideNav } from '../shared_elements';
import * as actions from '../shared_elements/actions'
import config from '../config';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin(){
  if(document.visibilityState || document.hasFocus){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
    setTimeout(() =>{
      if(!lessorAccess){
        localStorage.clear();
        if(!location.includes('login') && !location.includes('signup') && !location.includes('password/create') && !location.includes('create-successfully') && !location.includes('reset-successfully') && !location.includes('password/reset') && !location.includes('termscondition') && !location.includes('data-policy') && !location.includes('user/activate') && !location.includes('otp-verification') && !location.includes('forgot') && !location.includes('view-shared')){
          // window.location.assign('/login');
          // window.location.reload()
        }
      }else{
        if(config.api.networkInterface.includes(lessorAccess.environment)){
          if(lessorAccess.id){
            if(!getLocalStorageInfo() || (getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor)){
               authorizeAutoLogin({lessor_id:lessorAccess.id})
             }else if(!location.includes('select-lessor') && !location.includes('otp')){
               if(userInfo && userInfo.defaultLessor && parseInt(lessorAccess.id) !== userInfo.defaultLessor.id || getLocalStorageInfo().access !== lessorAccess.access){
                 authorizeAutoLogin({lessor_id:lessorAccess.id})
               }
             }
          }else if(getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor){
            // window.location.assign('/select-lessor')
          }else {
            if((userInfo && userInfo.defaultLessor == undefined) || (!userInfo && lessorAccess)){
              // getLessorsList()
            }
          }
        }
      }
    })
  }
}
export default function PageLayout(HocComponent, extraProps={}){
  class PageLayout extends Component{
    layoutMarginProps = () => {
      if(extraProps.layoutPhase){
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return '50px 0 0 0'
          case 1:
            // Desktop with Primary Header + Primary Sidenav
            return '0px 0 0 70px'
          case 2:
            // Desktop with Primary Header + Primary Sidenav + Secondary Sidenav
            return '0 0 0 260px';
          default:
            return '0'
        }
      }else{
        return '0'
      }
    }
    // componentDidMount() {
    //   getLessorsList()
    // }
    render(){
      const lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
      if(lessorAccess && lessorAccess.access && lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment)){
        return (
          <section>
            <Fragment>
              { isMobile ? <MobileHeader /> :
                <Fragment>
                  <DesktopHeader />
                  <DeskTopSidebar />
                </Fragment>

              }
            </Fragment>
            { isMobile ? null : extraProps.layoutPhase === 2 ? <SecondarySideNav />:null}
            <main style={{margin: isMobile ? '10px 0 0 0' : this.layoutMarginProps() , padding: isMobile ? '15px 15px 15px 15px' : '20px 20px 5px 20px'}}>
              <div>
                <MixpanelConsumer>
                  {mixpanel => <HocComponent mixpanel={mixpanel} {...this.props} />}
                </MixpanelConsumer>
              </div>
            </main>
          </section>
        );
      }else{
          return <Redirect to="/login" />
      }

    }
  }
  const mapStateToProps = (state) => ({
    pltConstants: state.shareReducer.pltConstants,
    applicationSwitcher: state.shareReducer.applicationSwitcher,
  })
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      toggleTheAppSwitcher: (flag) => dispatch({type:actions.APPS_SWITCHER, payload:flag}),
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(PageLayout)
}
