import React, { useState } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';
import EngineGraph from './EngineGraph';
import EngineFilter from './EngineFilter';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export default function EngineSection({ getGraphListFn, engineData, engineLoader, setEngineFilter, engineFilter, getResponseBack, callExternalFunction, tabIndex }) {
    const [engineGraphType, setEngineGraphType] = useState({ label: 'Bar Plot', value: 1 })
    return (
        <div>
            <Paper className="generic-filter-block">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <EngineFilter
                            setFilter={setEngineFilter}
                            filter={engineFilter}
                            getResponseBack={getResponseBack}
                            processingLoader={null}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {engineLoader ?
                            <Skeleton variant="rect" animation='wave' height={350} />
                            :
                            <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                                <div className='events-bar-chart-title' style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                                    <h4>  Engine Graph</h4>
                                    {
                                        engineData?.engine_type?.length ?
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                <Autocomplete
                                                    className='chart-type-switcher'
                                                    options={[{ label: 'Bar Plot', value: 1 }, { label: 'Line Plot', value: 2 }]}
                                                    getOptionLabel={option => option.label}
                                                    value={engineGraphType || null}
                                                    style={{ width: 135, marginRight: '20px' }}
                                                    disableClearable={true}
                                                    id="aircraft_type"
                                                    onChange={(e, value) => setEngineGraphType(value)}
                                                    renderInput={params => <TextField {...params} InputLabelProps={{ shrink: 'true', }} />}
                                                />
                                                <span style={{ cursor: 'pointer', height: '18px' }} onClick={() => { getGraphListFn(engineData, 'Engine Graph') }} >
                                                    <OpenInNewIcon color='primary' style={{ fontSize: '20px' }} />
                                                </span>
                                            </span>
                                            : null}
                                </div>
                                <EngineGraph
                                    tabIndex={tabIndex}
                                    engineGraphType={engineGraphType?.value}
                                    callExternalFunction={callExternalFunction}
                                    filter={engineFilter}
                                    engineData={engineData}
                                />
                            </Paper>
                        }
                    </Grid>
                </Grid>

            </Paper>
        </div>
    )
}
