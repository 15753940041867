import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
require('highcharts/modules/exporting')(Highcharts);
const EngineGraph = ({ engineData, engineGraphType, filter, callExternalFunction , tabIndex}) => {
    let engineType = engineData?.engine_type || []
    let avgLOW = engineData?.avg_life_on_wing || []
    let llpMrRate = engineData?.total_llp_mr_rate || []
    let llpSvCost = engineData?.total_llp_sv_cost || []
    let prsvMrRate = engineData?.total_prsv_mr_rate || []
    let prsvSvCost = engineData?.total_prsv_sv_cost || []

    const options = {
        chart: {
            type:engineGraphType ===1? 'column': 'line',
        },

        title: {
            text: ''
        },

        subtitle: {
            text: ''
        },

        xAxis: {
            categories: engineType
        },

        yAxis: [{ // Primary axis
            className: 'highcharts-color-0',
            title: {
                text: [1, 2].includes(filter?.graph_comparision?.value) ? 'Life on Wing (FH)' : 'Engine Cost (US$)'
            }
        }, { // Secondary axis
            className: 'highcharts-color-1',
            opposite: true,
            title: {
                text: filter?.graph_comparision?.value === 1 ? 'Engine Cost (US$)' : 'MR Rate (US$)'
            }
        }],

        plotOptions: {
            column: {
                stacking: 'normal',
                point: {
                    events: {
                        click: function (item, index) {
                            tabIndex == 'generic' ? console.log(''):
                            callExternalFunction('engine',
                                { xData: item?.point?.category, yData: item?.point?.series?.name, value: item?.point?.y });
                        }
                    }
                }
            },
            line: {
                stacking: 'normal',
                point: {
                    events: {
                        click: function (item, index) {
                            tabIndex == 'generic' ? console.log(''):
                            callExternalFunction('engine',
                                { xData: item?.point?.category, yData: item?.point?.series?.name, value: item?.point?.y });
                        }
                    }
                }
            }
        },

        series: filter?.graph_comparision?.value === 1 ? [{
            name: 'Life on Wing',
            color: '#90ed7d',
            stack: 'LOW',
            data: avgLOW,
            tooltip: {
                valueSuffix: ' FH'
            }
        },
        {
            name: 'LLP SV Cost',
            color: 'rgb(44, 175, 254)',
            data: llpSvCost,
            stack: 'svCost',
            yAxis: 1,
            tooltip: {
                valuePrefix: ' US$'
            }
        },
        {
            name: 'PRSV SV Cost',
            color: 'rgb(84, 79, 197)',
            data: prsvSvCost,
            stack: 'svCost',
            yAxis: 1,
            tooltip: {
                valuePrefix: ' US$'
            },
        }] : 
        filter?.graph_comparision?.value === 2 ?
        [
            {
                name: 'Life on Wing',
                color: '#90ed7d',
                stack: 'LOW',
                data: avgLOW,
                tooltip: {
                    valueSuffix: ' FH'
                }
            },
            {
                name: 'LLP MR Rate',
                color: 'rgb(44, 175, 254)',
                data: llpMrRate,
                stack: 'Europe',
                yAxis: 1,
                tooltip: {
                    valueSuffix: '/ FC',
                    valuePrefix: ' US$'
                }
            },
            {
                name: 'PRSV MR Rate',
                color: 'rgb(84, 79, 197)',
                data: prsvMrRate,
                stack: 'Europe',
                yAxis: 1,
                tooltip: {
                    valueSuffix: '/ FH',
                    valuePrefix: ' US$'
                },
            }
        ] : [
            {
                name: 'LLP SV Cost',
                color: '#33E0FF',
                data: llpSvCost,
                stack: 'svCost',
                tooltip: {
                    valuePrefix: ' US$'
                }
            },
            {
                name: 'PRSV SV Cost',
                color: '#338AFF',
                data: prsvSvCost,
                stack: 'svCost',
                tooltip: {
                    valuePrefix: ' US$'
                },
            },
            {
                name: 'LLP MR Rate',
                color: '#5ACD7F',
                data: llpMrRate,
                stack: 'mrRate',
                yAxis: 1,
                tooltip: {
                    valueSuffix: '/ FC',
                    valuePrefix: ' US$'
                }
            },
            {
                name: 'PRSV MR Rate',
                color: '#1D934A',
                data: prsvMrRate,
                stack: 'mrRate',
                yAxis: 1,
                tooltip: {
                    valueSuffix: '/ FH',
                    valuePrefix: ' US$'
                },
            }
        ],
        credits: {
            enabled: false
        },
    }

    return (
        <div>
            <HighchartsReact
                key={filter?.graph_comparision?.value}
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default EngineGraph;
