import React, { Fragment, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Paper, Tooltip, Button, TextField, InputAdornment } from '@material-ui/core';
import { regexConstants } from '../../../constants/regEx';
import { getLocalStorageInfo, getCurrencyFormat } from '../../../utils'
const EngineSVLow = ({isLocked, workScope, updateLifeOnwing, updateLowDataToEngine, updateLowApi, getResponseBack}) => {
    const [lowEdit, setLowEdit] = useState(false);
    return(
        <Fragment>
            <div style={workScope?.low_info && workScope?.low_info?.length ?{display:'flex', justifyContent:'center', alignItems:'center'}:{}}>
            <div>
            { lowEdit == false ?
                <Fragment>
                    { !(workScope.actual_life_of_wings !== undefined && workScope.actual_life_of_wings !== null) || (workScope.life_of_wings == workScope.actual_life_of_wings) ?
                        <Fragment>
                            <div>
                                <span style={workScope.life_limited_by_llp ? { color: '#f44337' } : {}}>
                                    {Math.ceil(workScope.life_of_wings)} Hrs
                                    { getLocalStorageInfo().user.permission['mr_calculator'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'] && getLocalStorageInfo().user.permission['mr_calculator']['analyze_mr_cash_flow'].indexOf('U') !== -1 && !isLocked ?
                                        <Tooltip title="Edit" placement="top" arrow><EditIcon style={{cursor:'pointer'}} color="primary" fontSize="small" className="sv-edit-icon" onClick={() => { setLowEdit(lowEdit ? false : true); }} /></Tooltip> : null
                                    }
                                </span>
                                
                            </div>
                            {workScope.life_limited_by_llp ? <span className="restricted-llp" style={{fontSize:'11px', background:'#fff1b6', padding:'2px'}}> {workScope.life_limited_by_llp} </span> : null}
                        </Fragment> :
                        <Fragment>
                            <div className="differentiation-values">
                                <span style={workScope.life_limited_by_llp ? { color: '#f44337' } : {}}>{Math.ceil(workScope.life_of_wings)} Hrs
                                    {!isLocked ?
                                        <Tooltip title="Edit" placement="top" arrow>
                                            <EditIcon style={{cursor:'pointer'}} color="primary" fontSize="small" className="sv-edit-icon" onClick={() => { setLowEdit(lowEdit ? false : true); }} />
                                        </Tooltip>  : null
                                    }
                                </span>
                                
                                <p style={{color:'#ff0000', fontSize:'12px'}}>
                                    {workScope.actual_life_of_wings}
                                    <Tooltip title="Original Value" arrow>
                                        <InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} />
                                    </Tooltip>
                                </p>
                            </div>
                            {workScope.life_limited_by_llp ? <span className="restricted-llp" style={{display:'block', fontSize:'11px', background:'#fff1b6', padding:'2px'}}> {workScope.life_limited_by_llp} </span> : null}
                        </Fragment>
                    }
                </Fragment> :
                <Fragment>
                    <div style={{display:"flex", alignItems:'center', justifyContent:'center'}}>
                    <TextField
                        style={{ width: '150px', verticalAlign: 'baseline',}}
                        id="low"
                        margin='none'
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        value={workScope.life_of_wings}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => { updateLifeOnwing(e.target.value); updateLowDataToEngine(e.target.value) }}
                    />
                    <span>
                        <Tooltip title="Cancel" placement="top" arrow>
                            <CancelIcon onClick={() => { getResponseBack(); setLowEdit(lowEdit ? false : true) }} style={{ color: '#f50057', cursor: 'pointer', height: '20px' }} />
                        </Tooltip>
                    </span>
                    <span onClick={() => { updateLowApi(); setLowEdit(lowEdit ? false : true) }}>
                        <Tooltip title="Save" placement="top" arrow>
                            <DoneIcon style={{ color: '#3f51b5', marginLeft: '5px', cursor: 'pointer', height: '20px' }} />
                        </Tooltip>
                    </span>
                    </div>
                </Fragment>
            }
            </div>
            {
                workScope?.low_info && workScope?.low_info.length ?
                <div>
                    <Tooltip title={workScope?.low_info} placement="top" arrow>
                        <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />
                    </Tooltip> 
                    </div>
                    : null
            }
            </div>
        </Fragment>
    )
}
export default EngineSVLow;