import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import { displayDateFormatShort } from '../../../constants';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { onCheckPermission } from '../../../utils';
const Portfolio = ({ item, history, onEdit, onDelete }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (id) => {
        setShowMore({
            ...showMore,
            [id]: !showMore[id],
        });
    };
    return (
        <TableRow onClick={() => history.push(`/portfolio/${item.slug}`)}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.no_of_assets}</TableCell>
            <TableCell>
                {item?.remarks && item?.remarks?.length > 30 ? (
                    <div style={{minWidth:'200px', maxWidth: '420px' }} >
                        {showMore[item?.id] && showMore[item?.id] ? (
                            <span> {item?.remarks}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault();e.stopPropagation(); toggleDescription(item.id); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.remarks.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault();e.stopPropagation(); toggleDescription(item.id); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div>{item.remarks || '--'}</div>
                }
            </TableCell>
            <TableCell>{item?.created_by?.name ? item.created_by.name : '--'}</TableCell>
            <TableCell>{item?.created_at ? moment(item.created_at).format(displayDateFormatShort) : '--'}</TableCell>
            <TableCell>{item?.updated_by?.name ? item.updated_by.name : '--'}</TableCell>
            <TableCell>{item?.updated_at ? moment(item.updated_at).format(displayDateFormatShort) : '--'}</TableCell>
            <TableCell>{item?.status?.label ? item.status.label : '--'}</TableCell>
            <TableCell align='right' >
                <ul className='list-inline' style={{width:'60px'}}>
                    <li className='list-inline-item'>
                        <VisibilityIcon color='primary' fontSize='small' />
                    </li>
                    {
                        onCheckPermission('mr_calculator', 'portfolio', 'U') ?
                            <li className='list-inline-item' onClick={(e) => { e.preventDefault(); e.stopPropagation(); onEdit() }}>
                                <EditIcon color='primary' fontSize='small' />
                            </li> : null}
                    {
                        onCheckPermission('mr_calculator', 'portfolio', 'D') ?
                            <li className='list-inline-item' onClick={(e) => { e.preventDefault(); e.stopPropagation(); onDelete() }}>
                                <DeleteOutlineIcon color='secondary' fontSize='small' />
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
export default withRouter(Portfolio);