import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
const FleetHeader = ({ tabIndex }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell style={{ width: '30px' }}></TableCell>
                <TableCell>Serial Number</TableCell>
                {tabIndex === 'airframe' ?
                    <>
                        <TableCell>Aircraft Type</TableCell>
                        <TableCell>Date of Manufacture</TableCell>
                        <TableCell>Last Done</TableCell>
                        <TableCell style={{ width: '110px' }} > MR Rate</TableCell>
                        <TableCell>Next Event</TableCell>
                        <TableCell>Next Due</TableCell>
                        <TableCell>Event Cost</TableCell>
                        <TableCell>Life Remaining</TableCell>
                        <TableCell>Life Consumed</TableCell>
                        <TableCell>Fund Balance</TableCell>
                        <TableCell>Time Remaining</TableCell>
                    </> :
                    tabIndex === 'engines' ?
                        <>
                            <TableCell>Engine Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Position</TableCell>
                            <TableCell>Region of Operation</TableCell>
                            <TableCell>TSN</TableCell>
                            <TableCell>CSN</TableCell>
                            <TableCell>Utilization As of</TableCell>
                            <TableCell>Last Done</TableCell>
                            <TableCell>TSN @ LSV</TableCell>
                            <TableCell>CSN @ LSV</TableCell>
                            <TableCell style={{ width: '110px' }} > PRSV MR Rate</TableCell>
                            <TableCell>PRSV Next Due</TableCell>
                            <TableCell>PRSV Cost</TableCell>
                            <TableCell>PRSV Life Remaining</TableCell>
                            <TableCell>PRSV Life Consumed</TableCell>
                            <TableCell>LLP Remaining Cycles</TableCell>
                            <TableCell style={{ width: '110px' }}>LLP MR Rate</TableCell>
                            <TableCell>LLP Replacement Cost</TableCell>
                            <TableCell>Titled MSN</TableCell>
                            <TableCell>On-Wing MSN</TableCell>
                            <TableCell>Time Remaininig</TableCell>
                        </> :
                        tabIndex === 'apu' ?
                            <>
                                <TableCell>APU Type</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>TSN</TableCell>
                                <TableCell>CSN</TableCell>
                                <TableCell>Utilization As of</TableCell>
                                <TableCell>Last Done</TableCell>
                                <TableCell>TSN @ LSV</TableCell>
                                <TableCell style={{ width: '110px' }} > MR Rate</TableCell>
                                <TableCell>Next Due</TableCell>
                                <TableCell>Event Cost</TableCell>
                                <TableCell>Life Remaining</TableCell>
                                <TableCell>Life Consumed</TableCell>
                                <TableCell>Fund Balance</TableCell>
                                <TableCell>Titled MSN</TableCell>
                                <TableCell>On-Wing MSN</TableCell>
                                <TableCell>Time Remaininig</TableCell>
                            </> :
                            tabIndex === 'lg' ?
                                <>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>TSN</TableCell>
                                    <TableCell>CSN</TableCell>
                                    <TableCell>Utilization As of</TableCell>
                                    <TableCell>Last Done</TableCell>
                                    <TableCell>CSN @ LSV</TableCell>
                                    <TableCell style={{ width: '110px' }} > MR Rate</TableCell>
                                    <TableCell>Next Due</TableCell>
                                    <TableCell>Event Cost</TableCell>
                                    <TableCell>Life Remaining</TableCell>
                                    <TableCell>Life Consumed</TableCell>
                                    <TableCell>Fund Balance</TableCell>
                                    <TableCell>Titled MSN</TableCell>
                                    <TableCell>On-Wing MSN</TableCell>
                                    <TableCell>Time Remaininig</TableCell>
                                </> :
                                null
                }
                <TableCell align="right">Actions</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default FleetHeader;