import React, { Fragment } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { Grid, TextField, Paper, InputAdornment, Tooltip } from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';
import { TableListComp } from '../../../shared_elements';
import ProjectionEvent from './ProjectionEvent';
import EventsBarChart from './EventsBarChart'
import { projectionEventHd } from '../';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat, getNumberDecimalFormat } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel'
import { regexConstants } from '../../../constants/regEx';
const AssemblyEventInfo = ({assemblyType,log_id, assemblyInfo, assemblyInfoSimulated, checkYr='', simulationType, onFieldChange, financeError, updateErrorField, updateEventsErrorField, generalInfo}) => {
  let events = Object.assign({}, assemblyInfo.events);
  return(
    <div className='assembly-events'>  
      <Paper style={{borderRadius:0}} className="mnt-table-list">
          <div className="event-name">
          <h4>{assemblyType === 'airframe' ? `${assemblyInfo.name.replace('s', '')} Check` : assemblyInfo.name}</h4>
          </div>
          <div className='event-lists'>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} sm={3}>
                <div>
                  <TextField
                    disabled={simulationType === '1' || simulationType === '3' ? true:false}
                    id="mr"
                    fullWidth
                    label="MR Rate"
                    margin="none"
                    error={ financeError && financeError.mr ? true : false}
                    helperText={ financeError && financeError.mr ? financeError.mr : ''}
                    value={ simulationType === '3' ? assemblyInfoSimulated.mr : assemblyInfo.mr}
                    onFocus={(e) => financeError && financeError.mr ?  updateErrorField(assemblyType, 'mr', '', checkYr) : e.preventDefault()}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'mr', e.target.value, assemblyType, checkYr):e.preventDefault()}}
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      startAdornment: <InputAdornment  position="start">US$</InputAdornment>,
                      endAdornment: <InputAdornment position="end">/ {assemblyInfo.mr_interval == "Monthly" ? "Month" :assemblyInfo.mr_interval == "FH" ? "APU H" :assemblyInfo.mr_interval }</InputAdornment>
                    }}
                    variant="outlined"
                  />
                </div>
                { simulationType === '3' && assemblyInfo.mr !== assemblyInfoSimulated.mr ? 
                  <p style={{color:'#ff0000', fontSize:'12px'}}>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{assemblyInfo.mr}</NumericLabel>
                    <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                  </p>:null
                }
              </Grid>
              <Grid item xs={12} md={3}>
                <div>
                  <TextField
                    disabled={simulationType === '1' || simulationType === '3' ? true:false}
                    id="escalation_percentage"
                    fullWidth
                    label="MR Rate Esc.(Annual)"
                    margin="none"
                    value={simulationType === '3' ? assemblyInfoSimulated.escalation_percentage :assemblyInfo.escalation_percentage}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ?  onFieldChange(e, 'escalation_percentage', e.target.value, assemblyType, checkYr):e.preventDefault()}}
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    variant="outlined"
                  />
                </div>
                { simulationType === '3' && assemblyInfo.escalation_percentage !== assemblyInfoSimulated.escalation_percentage ? 
                  <p style={{color:'#ff0000', fontSize:'12px'}}>
                    {assemblyInfo.escalation_percentage}%
                    <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                  </p>:null
                }
              </Grid>
              <Grid item xs={12} md={3}>
                <div>
                  <TextField
                    disabled={simulationType === '1' || simulationType === '3' ? true:false}
                    id="cost_escalation"
                    fullWidth
                    label="Event Cost Esc.(Annual)"
                    margin="none"
                    value={ simulationType === '3' ? assemblyInfoSimulated.cost_escalation :assemblyInfo.cost_escalation}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'cost_escalation', e.target.value, assemblyType, checkYr):e.preventDefault()}}
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    variant="outlined"
                  />
                </div>
                { simulationType === '3' && assemblyInfo.cost_escalation !== assemblyInfoSimulated.cost_escalation ? 
                  <p style={{color:'#ff0000', fontSize:'12px'}}>
                    {assemblyInfo.cost_escalation}%
                    <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                  </p>:null
                }
              </Grid>
            </Grid>
            <div className='table-list'>
              <TableListComp
                heads={projectionEventHd}
                Tooltip={true}
                tooltipContent={
                  <div>
                    Balance as of:<br/>
                    Fund Date : {assemblyInfo.fund_date ? moment(assemblyInfo.fund_date).format(displayDateFormatShort):'--' } <br/>
                  Fund Amount: {<NumericLabel params={getCurrencyFormat({shortFormat:false})}>{assemblyInfo.fund_amt}</NumericLabel> }
                  </div>
                }
                data={Object.keys(events).map((label, index) =>
                  <ProjectionEvent
                    label={label}
                    assemblyType={assemblyType}
                    index={index}
                    checkYr={checkYr}
                    updateEventsErrorField={updateEventsErrorField}
                    eventsInfo={assemblyInfo.events}
                    eventsInfoSimulated={assemblyInfoSimulated.events}
                    simulationType={simulationType}
                    financeError={financeError && financeError.events}
                    generalInfo={generalInfo}
                    onFieldChange={(e, keyParam, value, eventId) => onFieldChange(e, keyParam, value, assemblyType, checkYr, eventId)}
                  />
                )}
              />
            </div>
          </div>
        </Paper>
    </div>
    
  )
}
export default AssemblyEventInfo;
