import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
const PortfolioHeader = ({}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>No of Assets</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Modified By</TableCell>
                <TableCell>Modified At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align='right'>Actions</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default PortfolioHeader;