import React , {useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const EngineGraph = ({data, filter}) => {
    let serialNumber = data?.serial_number || []
    let avgMrRate = data?.mr_rate_graph || []
    let avgSvCost = data?.sv_cost_graph || []
    let avgLOW = data?.fund_balance_graph || []
    let y1data =  [1,2].includes(filter?.value) ? avgLOW : avgSvCost
    let y2data =  filter?.value === 1 ? avgSvCost : avgMrRate
    let maxY1data = Math.max(...y1data) + Math.min(...y1data) > 0 ? Math.max(...y1data) : Math.abs(Math.min(...y1data)) 
    let minY1data = Math.max(...y1data) + Math.min(...y1data) > 0 ? -Math.max(...y1data) : Math.min(...y1data) 
    let maxY2data = Math.max(...y2data) + Math.min(...y2data) > 0 ? Math.max(...y2data) : Math.abs(Math.min(...y2data)) 
    let minY2data = Math.max(...y2data) + Math.min(...y2data) > 0 ? -Math.max(...y2data) : Math.min(...y2data) 
    const options = {
        chart: {
            type: 'column',
        },
    
        title: {
            text: '',
        },
    
        subtitle: {
            text: '',
        },
    
        xAxis: {
            categories: serialNumber,
        },
    
        yAxis: [
            {
                className: 'highcharts-color-0',
                title: {
                    text: [1, 2].includes(filter?.value) ? 'Fund balance (US$)' : 'SV Cost (US$)',
                },
                min: minY1data,
                max: maxY1data
            },
            {
                className: 'highcharts-color-1',
                opposite: true,
                title: {
                    text: filter?.value === 1 ? 'SV Cost (US$)' : 'MR Rate (US$)',
                },
                min: minY2data, // Set to the minimum value in y2data
                max: maxY2data, // Set to the maximum value in y2data
            },
        ],
    
        plotOptions: {
            column: {
                borderRadius: 2,
            },
        },
    
        series: [
            {
                name: [1, 2].includes(filter?.value) ? 'Fund balance' : 'SV Cost',
                color: [1, 2].includes(filter?.value) ? '#90ed7d' : '#0066FF',
                data: y1data,
                negativeColor: 'red',
                tooltip: {
                    valuePrefix: ' US$',
                },
            },
            {
                name: filter?.value === 1 ? 'SV Cost' : 'MR Rate',
                data: y2data,
                color: filter?.value === 1 ? '#0066FF' : '#F9AC2A',
                yAxis: 1,
                negativeColor: 'red',
                tooltip: {
                    valuePrefix: ' US$',
                },
            },
        ],
    
        credits: {
            enabled: false,
        },
        alignTicks: true, // Set to true to align the ticks
    };
    return(
        <div>
            <HighchartsReact
                key={filter?.value}
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default EngineGraph;
