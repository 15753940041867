import { TableCell, TableRow, Checkbox, Tooltip } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat, onCheckPermission } from '../../../utils';
import NumericLabel from 'react-pretty-numbers';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
const FleetList = ({ item, tabIndex, selectedAssets, onAssetSelection, onEdit, onDelete, tab }) => {
    return (
        <TableRow>
            <TableCell style={{ width: '30px' }}>
                <Checkbox
                    checked={selectedAssets.includes(item.id)}
                    onChange={(e) => onAssetSelection(e.target.checked, item.id)}
                    color='primary'
                    size='small'
                />
            </TableCell>
            <TableCell>
                {item.serial_number}
            </TableCell>
            {
                tabIndex === 'airframe' ?
                    <>
                        <TableCell>{item?.asset_type?.name ? item.asset_type.name : '--'}</TableCell>
                        <TableCell>{item.date_of_manufacture ?moment(item.date_of_manufacture).format(displayDateFormatShort):'--'}</TableCell>
                        <TableCell>{item.lsv_date ? moment(item.lsv_date).format(displayDateFormatShort) : '--'}</TableCell>
                        <TableCell>
                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr_rate ? item?.mr_rate : 0}</NumericLabel>
                            <br />
                            ({moment(item?.updated_at).format('MMM, YYYY')})
                        </TableCell>
                        <TableCell>{item?.extras?.maintenance_check?.name ? item.extras.maintenance_check.name.concat(' Year Check') : '--'}</TableCell>
                        <TableCell>{moment(item.nsv_date).format(displayDateFormatShort)}</TableCell>
                        <TableCell>
                            <Tooltip title={<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.sv_cost}</NumericLabel>} arrow>
                                <div style={{ whiteSpace: 'nowrap' }}><NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.sv_cost}</NumericLabel></div>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{item?.life_remaining ? item.life_remaining + ' ' + item?.life_units : ''}</TableCell>
                            <TableCell>{item?.life_consumed ? item.life_consumed + ' ' + item?.life_units : ''}</TableCell>
                        <TableCell>
                            <Tooltip title={<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.fund_balance}</NumericLabel>} arrow>
                                {
                                    item.fund_balance < 0 ?
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            - <NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.fund_balance * (-1)}</NumericLabel>
                                        </div> :
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            <NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.fund_balance}</NumericLabel> </div>
                                }
                            </Tooltip>
                        </TableCell>
                        <TableCell>{item.time_remaining ? item.time_remaining : 0}</TableCell>
                    </>
                    :
                    tabIndex === 'engines' ?
                        <>
                            <TableCell>{item?.asset_type?.name ? item.asset_type.name : '--'}</TableCell>
                            <TableCell>{item.status || '--'}</TableCell>
                            <TableCell>{item.position?.label || '--'}</TableCell>
                            <TableCell>{item?.location?.address ? item.location.address : '--'}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>{item.tsn ? item.tsn : 0}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>{item.csn ? item.csn : 0}</TableCell>
                            <TableCell>{item.as_of_date ? moment(item.as_of_date).format(displayDateFormatShort) : '--'}</TableCell>
                            <TableCell>{item.lsv_date ? moment(item.lsv_date).format(displayDateFormatShort) : '--'}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>{item.tsn_at_lsv ? item.tsn_at_lsv : 0}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>{item.csn_at_lsv ? item.csn_at_lsv : 0}</TableCell>
                            <TableCell>
                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr_rate ? item?.mr_rate : 0}</NumericLabel>
                                <br />
                                ({moment(item?.updated_at).format('MMM, YYYY')})
                            </TableCell>
                            <TableCell>
                                <div>
                                    <span style={item?.extras?.life_limited_by_llp ? { color: '#f44337' } : {}}>
                                        {moment(item.nsv_date).format(displayDateFormatShort)}
                                    </span>
                                    {item?.extras?.life_limited_by_llp ? <span className="restricted-llp" style={{ fontSize: '11px', background: '#fff1b6', padding: '2px' }}> {item.extras.life_limited_by_llp} </span> : null}
                                </div>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.sv_cost}</NumericLabel>} arrow>
                                    <div style={{ whiteSpace: 'nowrap' }}><NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.sv_cost}</NumericLabel></div>
                                </Tooltip>
                            </TableCell>
                            <TableCell>{item?.life_remaining ? item.life_remaining + ' ' + item?.life_units : ''}</TableCell>
                            <TableCell>{item?.life_consumed ? item.life_consumed + ' ' + item?.life_units : ''}</TableCell>
                            <TableCell>{item?.extras?.llp_limiter_cycle ? item.extras.llp_limiter_cycle : '--'} {' '}
                                ({item?.extras?.llp_limiter_module ? item?.extras?.llp_limiter_module : '--'})
                            </TableCell>
                            <TableCell>
                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.extras?.llp_mr_rate ? item?.extras?.llp_mr_rate : 0}</NumericLabel>
                                <br />
                                ({moment(item?.updated_at).format('MMM, YYYY')})
                            </TableCell>
                            <TableCell>
                                <Tooltip title={<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.extras?.llp_data?.sv_cost}</NumericLabel>} arrow>
                                    <div style={{ whiteSpace: 'nowrap' }}> <NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item?.extras?.llp_data?.sv_cost ? item.extras.llp_data.sv_cost : 0}</NumericLabel></div>
                                </Tooltip>
                            </TableCell>
                            <TableCell>{item.titled_msn?item.titled_msn :'--'}</TableCell>
                            <TableCell>{item.on_wing_msn? item.on_wing_msn :'--'}</TableCell>
                            <TableCell>{item.time_remaining ? item.time_remaining : 0}</TableCell>
                        </>
                        :
                        tabIndex === 'apu' ?
                            <>
                                <TableCell>{item?.asset_type?.name ? item.asset_type.name : '--'}</TableCell>
                                <TableCell>{item.status || '--'}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>{item.tsn ? item.tsn : 0}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>{item.csn ? item.csn : 0}</TableCell>
                                <TableCell>{item.as_of_date ? moment(item.as_of_date).format(displayDateFormatShort) : '--'}</TableCell>
                                <TableCell>{item.lsv_date ? moment(item.lsv_date).format(displayDateFormatShort) : '--'}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>{item.tsn_at_lsv ? item.tsn_at_lsv : 0}</TableCell>
                                <TableCell>
                                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr_rate ? item?.mr_rate : 0}</NumericLabel>
                                    <br />
                                    ({moment(item?.updated_at).format('MMM, YYYY')})
                                </TableCell>
                                <TableCell>{moment(item.nsv_date).format(displayDateFormatShort)}</TableCell>
                                <TableCell>
                                    <Tooltip title={<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.sv_cost}</NumericLabel>} arrow>
                                        <div style={{ whiteSpace: 'nowrap' }}><NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.sv_cost}</NumericLabel></div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{item?.life_remaining ? item.life_remaining + ' ' + item?.life_units : ''}</TableCell>
                            <TableCell>{item?.life_consumed ? item.life_consumed + ' ' + item?.life_units : ''}</TableCell>
                                <TableCell>
                                    <Tooltip title={<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.fund_balance}</NumericLabel>} arrow>
                                        {
                                            item.fund_balance < 0 ?
                                                <div style={{ whiteSpace: 'nowrap' }}>
                                                    - <NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.fund_balance * (-1)}</NumericLabel>
                                                </div> :
                                                <div style={{ whiteSpace: 'nowrap' }}>
                                                    <NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.fund_balance}</NumericLabel> </div>
                                        }
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{item.titled_msn?item.titled_msn :'--'}</TableCell>
                                <TableCell>{item.on_wing_msn? item.on_wing_msn :'--'}</TableCell>
                                <TableCell>{item.time_remaining ? item.time_remaining : 0}</TableCell>
                            </>
                            :
                            tabIndex === 'lg' ?
                                <>
                                    <TableCell>{item.position?.label || '--'}</TableCell>
                                    <TableCell>{item.status || '--'}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{item.tsn ? item.tsn : 0}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{item.csn ? item.csn : 0}</TableCell>
                                    <TableCell>{item.as_of_date ? moment(item.as_of_date).format(displayDateFormatShort) : '--'}</TableCell>
                                    <TableCell>{item.lsv_date ? moment(item.lsv_date).format(displayDateFormatShort) : '--'}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{item.csn_at_lsv ? item.csn_at_lsv : 0}</TableCell>
                                    <TableCell>
                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr_rate ? item?.mr_rate : 0}</NumericLabel>
                                        <br />
                                        ({moment(item?.updated_at).format('MMM, YYYY')})
                                    </TableCell>
                                    <TableCell>{moment(item.nsv_date).format(displayDateFormatShort)}</TableCell>
                                    <TableCell>
                                        <Tooltip title={<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.sv_cost}</NumericLabel>} arrow>
                                            <div style={{ whiteSpace: 'nowrap' }}> <NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.sv_cost}</NumericLabel></div>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{item?.life_remaining ? item.life_remaining + ' ' + item?.life_units : ''}</TableCell>
                                    <TableCell>{item?.life_consumed ? item.life_consumed + ' ' + item?.life_units : ''}</TableCell>
                                    <TableCell>
                                        <Tooltip title={<NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.fund_balance}</NumericLabel>} arrow>
                                            {
                                                item.fund_balance < 0 ?
                                                    <div style={{ whiteSpace: 'nowrap' }}>
                                                        - <NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.fund_balance * (-1)}</NumericLabel>
                                                    </div> :
                                                    <div style={{ whiteSpace: 'nowrap' }}>
                                                        <NumericLabel params={getCurrencyFormat({ shortFormat: true })}>{item.fund_balance}</NumericLabel> </div>
                                            }
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{item.titled_msn?item.titled_msn :'--'}</TableCell>
                                    <TableCell>{item.on_wing_msn? item.on_wing_msn :'--'}</TableCell>
                                    <TableCell>{item.time_remaining ? item.time_remaining : 0}</TableCell>
                                </>
                                : null

            }
            <TableCell align='right'>
                <ul className='list-inline'>
                    {
                        onCheckPermission('mr_calculator', tab, 'U') ?
                            <li className='list-inline-item' onClick={()=>{window.open(`/intermediate/${item?.log_maintenance}` ,'blank')}}>
                                <OpenInNewIcon color='primary' style={{ fontSize: '20px', cursor: 'pointer' }} />
                            </li>
                            : null}
                    {
                        onCheckPermission('mr_calculator', tab, 'U') ?
                            <li className='list-inline-item' onClick={onEdit}>
                                <EditIcon color='primary' fontSize='small' style={{ cursor: 'pointer' }} />
                            </li>
                            : null}
                    {
                        onCheckPermission('mr_calculator', tab, 'D') ?
                            <li className='list-inline-item' onClick={onDelete}>
                                <DeleteOutlineIcon color='secondary' fontSize='small' style={{ cursor: 'pointer' }} />
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
export default withRouter(FleetList);