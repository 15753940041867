import React, { Fragment } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { Grid, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { LabelValueCard } from '../../../shared_elements';
import { getCurrencyFormat, getNumberDecimalFormat } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const EngineEventInfo = ({engine, simulateEngine, simulationType, label, onFieldChange, financeError, updateErrorEngineField}) => {
  return(
    <div className="engine-basic-info">
      <Grid container spacing={1}>
        <Grid item md={6} sm={6}>
          <h4>Performance Restoration Shop Visit ( PRSV )</h4>
          <Grid container spacing={1}>
            <Grid item xs={12} md={10} lg={5}>
              <TextField
                disabled={simulationType === '1' || simulationType === '3'?true:false}
                id="engine_mr"
                label="MR Rate"
                fullWidth
                margin="none"
                value={simulationType === '3' ?simulateEngine.engine_mr :engine.engine_mr}
                InputLabelProps={{shrink: true}}
                error={financeError && financeError.engine_mr ? true : false}
                helperText={financeError && financeError.engine_mr ? financeError.engine_mr :''}
                onFocus={(e) => financeError && financeError.engine_mr ?  updateErrorEngineField('engine_mr','',label) : e.preventDefault()}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'engine_mr', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                InputProps={{
                  startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">/ {engine.prsv_mr_interval}</InputAdornment>
                }}
                variant="outlined"
              />
              { simulationType === '3' && engine.engine_mr !== simulateEngine.engine_mr ?
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.engine_mr}</NumericLabel> / {engine.prsv_mr_interval}
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>:null
              }
            </Grid>
            <Grid item xs={12} md={5} sm={6} lg={4}>
              <TextField
                disabled={simulationType === '1' || simulationType === '3'?true:false}
                id="engine_escalation"
                label="MR Rate Esc.(Annual)"
                fullWidth
                margin="none"
                value={simulationType === '3' ?simulateEngine.engine_escalation :engine.engine_escalation}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'engine_escalation', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                variant="outlined"
              />
              {simulationType === '3' && engine.engine_escalation !==  simulateEngine.engine_escalation ?
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  {engine.engine_escalation}%
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>:null
              }
            </Grid>
            <Grid item xs={12} md={5} sm={6} lg={3}>
              <TextField
                disabled={simulationType === '1' || simulationType === '3'?true:false}
                id="prsv_cost_escalation"
                label="Cost Esc.(Annual)"
                fullWidth
                margin="none"
                value={simulationType === '3' ? simulateEngine.prsv_cost_escalation :engine.prsv_cost_escalation}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'prsv_cost_escalation', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                variant="outlined"
              />
              { simulationType === '3' && engine.prsv_cost_escalation !==  simulateEngine.prsv_cost_escalation ?
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  {engine.prsv_cost_escalation}%
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>:null
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6}>
          <h4>LLP Replacement Shop Visit</h4>
          <Grid container spacing={1}>
            <Grid item xs={12} md={10} lg={5}>
              <TextField
                disabled={simulationType === '1' || simulationType === '3'?true:false}
                id="llp_mr"
                label="MR Rate"
                fullWidth
                margin="none"
                value={simulationType === '3' ?simulateEngine.llp_mr :engine.llp_mr}
                InputLabelProps={{shrink: true}}
                error={financeError && financeError.llp_mr ? true : false}
                helperText={financeError && financeError.llp_mr ? financeError.llp_mr : ''}
                onFocus={() => financeError && financeError.llp_mr && updateErrorEngineField('llp_mr','',label)}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'llp_mr', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                InputProps={{
                  startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">/ {engine.llp_mr_interval}</InputAdornment>
                }}
                variant="outlined"
              />
              { simulationType === '3' && engine.llp_mr !==  simulateEngine.llp_mr ? 
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{engine.llp_mr}</NumericLabel> / {engine.llp_mr_interval}
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>:null
              }
            </Grid>
            <Grid item xs={12} md={5} sm={6} lg={4}>
              <TextField
                disabled={simulationType === '1' || simulationType === '3'?true:false}
                id="llp_escalation"
                label="LLP Esc.(Annual)"
                fullWidth
                margin="none"
                value={simulationType === '3' ? simulateEngine.llp_escalation:engine.llp_escalation}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'llp_escalation', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                variant="outlined"
              />
              { simulationType === '3' && engine.llp_escalation !==  simulateEngine.llp_escalation ? 
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  {engine.llp_escalation}%
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>:null
              }
            </Grid>
            <Grid item xs={12} md={5} sm={6} lg={3}>
              <TextField
                disabled={simulationType === '1' || simulationType === '3'?true:false}
                id="llp_cost_escalation"
                label="Cost Esc.(Annual)"
                fullWidth
                margin="none"
                value={engine.llp_cost_escalation}
                InputLabelProps={{shrink: true}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'llp_cost_escalation', e.target.value, 'engines', label, 'engine'): e.preventDefault()}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                variant="outlined"
              />
              { simulationType === '3' && engine.llp_cost_escalation !==  simulateEngine.llp_cost_escalation ?
                <p style={{color:'#ff0000', fontSize:'12px'}}>
                  {engine.llp_cost_escalation}%
                  <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                </p>:null
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default EngineEventInfo;
