import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { fieldDateFormat, displayDateFormatShort, backendDateFormat } from '../../../constants';
import { Button, Grid, Paper, Tabs, Tab, Hidden, Container, Drawer, Link } from '@material-ui/core';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import ListAnalyticSW from "./ListAnalyticSW";
import EOLGraphs from "./EOLGraphs";
import moment from "moment";
import { EmptyCollection, PageLoader } from "../../../shared_elements";
import EolOutputList from "./EolOutputList";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
const EOLProjection = ({ match, mrLogDetail, callExternalFunction, exportEolLogApi }) => {
    const [eolConditions, setEolConditions] = useState([]);
    const [copyEolConditions, setCopyEolConditions] = useState([]);
    const [simulateEolConditions, setSimulateEolConditions] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [openAllAcc, setOpenAllAcc] = useState(false);
    const [simulationType, setSimulationType] = useState('1');
    const [assemblyIndex, setAssemblyIndex] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [listMode, setListMode] = useState(true)
    const [accordianArr, setAccordianArr]= useState([0])
    const [open,setOpen] = useState(false)
    const onChangeAccordian = (index, eolIndex) => {
        if (openAllAcc) {
            let accArr =eolConditions[eolIndex].eol_conditions.map((i , eolindex)=> eolindex).filter(i=> i !=index )
            setOpenAllAcc(false)
            setAccordianArr(accArr)
        }
        else {
            if (accordianArr.includes(index)) {
                let accArr = accordianArr.filter(item => item != index)
                setAccordianArr(accArr)
            }
            else {
                setAccordianArr([...accordianArr, index])
            }
        }
    }
    useEffect(() => {
        getEolProjectionReport();
    }, []);
    const getEolProjectionReport = () => {
        setLoading(true);
        globalGetService(`new-mr-calculator/eol-scenario/analyse/${match.params.id}/`)
        .then(response => {
            setLoading(false);
            if (checkApiStatus(response)) {
                setEolConditions(response.data.data);
                setCopyEolConditions(response.data.data);
            } else {

            }
        })
    }
    const onSimulateEolCondition = () => {
        setLoading(true);
        globalPostService(`new-mr-calculator/eol-scenario/simulate/${match.params.id}/`,{data:eolConditions})
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                setEolConditions(copyEolConditions);
                setSimulateEolConditions(response.data.data);
                setSimulationType('3');
            }else{

            }
        })
    }
    const onLeaseDatesChange =(event, keyParam, value, leaseIndex, conditionIndex)=>{
        if( keyParam == 'lease_end_date' ||keyParam == 'lease_start_date' ){
            let leaseDuration = null
            if (keyParam == 'lease_end_date' && eolConditions[leaseIndex].lease_start_date) {
              leaseDuration = moment(value).diff(moment(eolConditions[leaseIndex].lease_start_date), 'months', true)
            }
            else if (keyParam == 'lease_start_date' && eolConditions[leaseIndex].lease_end_date) {
              leaseDuration = moment(eolConditions[leaseIndex].lease_end_date).diff(moment(value), 'months', true)
            }
            leaseDuration =  Math.round(leaseDuration)
            setEolConditions(
                eolConditions.map((item, itemIndex) => leaseIndex !== itemIndex ? item: {
                    ...item,
                    ['lease_duration']: parseInt(leaseDuration),
                    [keyParam]: value
                })
                )
          }
          else{
            let endDate = moment(eolConditions[leaseIndex].lease_start_date).add(value, 'M').format(backendDateFormat)
            setEolConditions(
                eolConditions.map((item, itemIndex) => leaseIndex !== itemIndex ? item: {
                    ...item,
                    ['lease_end_date']: endDate,
                    [keyParam]: parseInt(value)
                })
            )
          }
    }
    const onFieldChange = (event, keyParam, value, leaseIndex, conditionIndex, eventIndex) => {
        if(eventIndex !== null){
            setEolConditions(
                eolConditions.map((item, itemIndex) => leaseIndex !== itemIndex ? item: {
                    ...item,
                    eol_conditions: item.eol_conditions.map((cond, condIndex) => condIndex !== conditionIndex?cond:{
                        ...cond,
                        event_details: cond.event_details.map((event, evIndex) => evIndex !== eventIndex ? event : {
                            ...event,
                            [keyParam]: value
                        })
                    })
                })
            )
        }else{
            setEolConditions(
                eolConditions.map((item, itemIndex) => leaseIndex !== itemIndex ? item: {
                    ...item,
                    eol_conditions: item.eol_conditions.map((cond, condIndex) => condIndex !== conditionIndex?cond:{
                        ...cond,
                        [keyParam]: value
                    })
                })
            )
        }
        
    }
    let exportDisplayCondition =eolConditions.map(item => item?.eol_conditions.map(eolItem=> eolItem?.component_name.name.includes('Airframe') || eolItem?.component_name.name.includes('Engine') || eolItem?.component_name.name.includes('Landing Gear') || eolItem?.component_name.name.includes('APU')).includes('false') )
    let exportDisplayCondn =eolConditions.map(item => item?.eol_conditions.map(eolItem=> eolItem?.component_name.name.trim().split(' ')[0] ))
    let condtnArr=['Airframe','Engine','APU','Landing']
    let showExportButton = exportDisplayCondn.map(logArr => condtnArr.every(item => logArr.includes(item)))
    return (
        <div>
            <Grid container className="mr-title">
                <Grid item xs={12} className="mr-log-info">
                <ul className="list-inline flex-centered" style={{marginTop:'6px', float:'left'}}>
                    <li>
                    {eolConditions.length?
                    <span className="notice-info" style={{fontSize:'13px', border:'1px solid'}}>
                    To modify the values and try various scenarios like Carry Fund Balance, Add SV at EOL, etc. Click on Simulate EOL scenarios.
                    </span>
                    : null
                }
                    </li>
                </ul>
                    <ul className="list-inline flex-centered" style={{ float: 'right' }}>
                    <li>
                    {eolConditions.length && showExportButton.includes(true)?
                    exportDisplayCondition.filter(item => item == true).length  == exportDisplayCondition.length ? null :
                    <Button variant="contained" color="primary" onClick={()=> exportEolLogApi(match, 'xlsx')}>Export </Button>
                    : null
                }
                </li> 
                        {listMode === true ?
                            <Fragment>
                                {simulationType === '1' && eolConditions.length?
                                    <li className="list-inline-item">
                                        <Button onClick={() => {setSimulationType('2'); setOpenAllAcc(true)}} size="small" color="primary" variant="contained">Simulate EOL Scenarios</Button>
                                    </li> : null
                                }
                                {simulationType === '2' ?
                                    <Fragment>
                                        <li className="list-inline-item">
                                            <Button onClick={() => {setSimulationType('1'); setSimulateEolConditions([]); getEolProjectionReport()}} color='primary' size='small' variant="outlined">Reset to Default</Button>
                                        </li>
                                        <li className="list-inline-item">
                                            <Button onClick={onSimulateEolCondition} size="small" color="primary" variant="contained">Apply</Button>
                                        </li>
                                    </Fragment> : null
                                }
                                {simulationType === '3' ?
                                    <Fragment>
                                        <li className="list-inline-item">
                                            <Button onClick={() => {setSimulationType('1'); setSimulateEolConditions([]); getEolProjectionReport()}} color='primary' size='small' variant="outlined">Reset to Default</Button>
                                        </li>
                                        <li className="list-inline-item">
                                            <Button onClick={() => {setSimulationType('2'); setOpenAllAcc(true)}} size="small" color="primary" variant="contained">Simulate Another EOL Scenarios</Button>
                                        </li>
                                    </Fragment> : null
                                }
                            </Fragment> : null
                        }
                        <li className='list-inline-item'>
                            <ListAnalyticSW
                                listMode={listMode}
                                toggleViewMode={(flag) => setListMode(flag)}
                            />
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Paper className='commverge-intermediate-card'>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Container style={{ position: 'relative', borderBottom: '1px solid #d7d7d7', background: 'rgb(232 234 234)', padding: '5px', marginBottom: '3px' }}>
                        <Grid className="flex-centered ">
                            <MenuOpenIcon
                                style={{ marginLeft: '5px', }}
                                fontSize='medium'
                                color="primary"
                                onClick={() => setOpen(true)}
                                />
                        </Grid>
                    </Container>
                    <Drawer anchor='left' open={open} onClose={()=>setOpen(false)}>
                        <Paper className="mobile-py-link" elevation={0} style={{ height: '100%', width: '200px', background: '#8073c1' }}>
                            <div className='side-bar-style'>
                                {eolConditions.map((eol, eolIndex) =>
                                    <Link onClick={() =>{ setAssemblyIndex(eolIndex);setOpen(false)}} className={`menu-option ${assemblyIndex === eolIndex ? 'active' : ''}`}>
                                        <h4>{`Lease ${eolIndex + 1}`}</h4>
                                        <p style={{color:'white',fontSize:'12px'}}>{moment(eol.lease_start_date).format(displayDateFormatShort)} - {moment(eol.lease_end_date).format(displayDateFormatShort)}</p>
                                    </Link>
                                )}
                            </div>
                        </Paper>
                    </Drawer>
                </Hidden>
                <Hidden only={['xs', 'sm']}>
                <div className='commverge-assembly-menu'>
                    {eolConditions.map((eol, eolIndex) =>
                        <div onClick={() => setAssemblyIndex(eolIndex)} className={`menu-option ${assemblyIndex === eolIndex ? 'active' : ''}`}>
                            <h6>{`Lease ${eolIndex + 1}`}</h6>
                            <p>{moment(eol.lease_start_date).format(displayDateFormatShort)} - {moment(eol.lease_end_date).format(displayDateFormatShort)}</p>
                        </div>
                    )}
                </div>
                </Hidden>
                <div className='commverge-assembly-projection' style={{ minHeight: `${window.innerHeight - 100}px` }}>
                    <div>
                        {eolConditions.map((eol, eolIndex) =>
                            eolIndex === assemblyIndex ? listMode ?
                                <EolOutputList
                                onLeaseDatesChange={onLeaseDatesChange}
                                mrLogDetail={mrLogDetail}
                                onChangeAccordian={onChangeAccordian} 
                                accordianArr={accordianArr}
                                    openAllAcc={openAllAcc}
                                    setOpenAllAcc={setOpenAllAcc}
                                    editMode={simulationType === '2' ? true:false}
                                    eol={eol}
                                    simulatedEol={simulationType === '3' && simulateEolConditions[eolIndex] ? simulateEolConditions[eolIndex]:{}}
                                    simulationType={simulationType}
                                    eolIndex={eolIndex}
                                    onFieldChange={onFieldChange}
                                />
                                :
                                <Fragment>
                                    {simulationType === '3' ?
                                        <Fragment>
                                            <Tabs
                                                value={tabIndex}
                                                onChange={(event, newValue) => setTabIndex(newValue)}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="normal"
                                            >
                                                <Tab label={`Without Simulation`} />
                                                <Tab label={`With Simulation`} />
                                            </Tabs>
                                            {tabIndex === 0 ?
                                                <EOLGraphs
                                                callExternalFunction={callExternalFunction}
                                                    eol={eol}
                                                    eolIndex={eolIndex}
                                                />:null
                                            }
                                            {tabIndex === 1 ?
                                                <EOLGraphs
                                                callExternalFunction={callExternalFunction}
                                                    eol={simulateEolConditions[eolIndex]}
                                                    eolIndex={eolIndex}
                                                />:null
                                            }
                                        </Fragment>:
                                        <EOLGraphs
                                        callExternalFunction={callExternalFunction}
                                            eol={eol}
                                            eolIndex={eolIndex}
                                        />
                                    }
                                    
                                </Fragment>
                                : null
                        )}
                    </div>
                    {
                        eolConditions.length ?
                            null :
                            isLoading ?
                                null :
                                <EmptyCollection title="No Lease Added!" />
                    }
                </div>
                {isLoading ? <PageLoader /> : null}
               
            </Paper>
            
        </div>
    )
}
export default withRouter(EOLProjection);