import React, { Fragment, useState, useEffect } from "react";
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { getCurrencyFormat } from '../../../utils';
import NumericLabel from 'react-pretty-numbers';
import InfoIcon from '@material-ui/icons/Info';

export default function EolLlpModalDetails({ item, llpModal, simulationType, simulatedItem }) {
    return (
        <TableRow>
            <TableCell style={item?.status == false ?{color:'red'}:{}} >{item.module_type ? item.module_type : '--'} </TableCell>
            <TableCell style={item?.status == false ?{color:'red'}:{}}>{item.description ? item.description : '--'} </TableCell>
            <TableCell style={item?.status == false ?{color:'red'}:{}}>{item?.expected_condition ? item.expected_condition : '--'} </TableCell>
            <TableCell style={item?.status == false ?{color:'red'}:{}}>{item.actual_condition ? item.actual_condition : '--'} </TableCell>
            {
                llpModal?.eol?.lease_type?.value != 3 ?
                    (simulationType === '1' || simulationType === '2') ?
                    llpModal?.eol?.lease_type?.value == 1 ?
                        <TableCell style={item?.status == false ?{color:'red'}:{}}>{item.mr_accruals ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.mr_accruals}</NumericLabel> : '--'} </TableCell>:
                        <TableCell style={item?.status == false ?{color:'red'}:{}}>{item.compensation ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.compensation}</NumericLabel> : '--'} </TableCell>
                        :
                        simulationType === '3' ?
                        llpModal?.eol?.lease_type?.value == 1 ?
                        item?.mr_accruals === simulatedItem.mr_accruals ?
                        <TableCell style={item?.status == false ?{color:'red'}:{}}>{item.mr_accruals ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.mr_accruals}</NumericLabel> : '--'} </TableCell>
                        :
                        <TableCell style={item?.status == false ?{color:'red'}:{}}>{simulatedItem.mr_accruals ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedItem.mr_accruals}</NumericLabel> : '--'}
                            <div className="differentiation-values">
                                <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr_accruals}</NumericLabel>
                                    <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                </p>
                            </div>
                        </TableCell>
                        :
                            item?.compensation === simulatedItem.compensation ?
                                <TableCell style={item?.status == false ?{color:'red'}:{}}>{item.compensation ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.compensation}</NumericLabel> : '--'} </TableCell>
                                :
                                <TableCell style={item?.status == false ?{color:'red'}:{}}>{simulatedItem.compensation ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedItem.compensation}</NumericLabel> : '--'}
                                    <div className="differentiation-values">
                                        <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.compensation}</NumericLabel>
                                            <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                        </p>
                                    </div>
                                </TableCell>
                            :
                            null
                    :
                    <>
                    {(simulationType === '1' || simulationType === '2') ?
                         <TableCell style={item?.status == false ?{color:'red'}:{}}>{item.mr_accruals ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.mr_accruals}</NumericLabel> : '--'} </TableCell>
                         :
                         simulationType === '3' ?
                         item?.mr_accruals === simulatedItem.mr_accruals ?
                         <TableCell style={item?.status == false ?{color:'red'}:{}}>{item.mr_accruals ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.mr_accruals}</NumericLabel> : '--'} </TableCell>
                         :
                         <TableCell style={item?.status == false ?{color:'red'}:{}}>{simulatedItem.mr_accruals ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedItem.mr_accruals}</NumericLabel> : '--'}
                             <div className="differentiation-values">
                                 <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                     <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mr_accruals}</NumericLabel>
                                     <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                 </p>
                             </div>
                         </TableCell>
                    :
                    null
                    }
                        <TableCell style={item?.status == false ?{color:'red'}:{}}>
                            {item.mirror_in_mirror_out_amt && item.mirror_in_mirror_out_payer == 'Lessor' ?
                                (simulationType === '1' || simulationType === '2') ?
                                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.mirror_in_mirror_out_amt}</NumericLabel>
                                    :
                                    simulationType === '3' ?
                                        item?.mirror_in_mirror_out_amt === simulatedItem.mirror_in_mirror_out_amt ?
                                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.mirror_in_mirror_out_amt}</NumericLabel>
                                            : 
                                            <>
                                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedItem.mirror_in_mirror_out_amt}</NumericLabel>
                                                <div className="differentiation-values">
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mirror_in_mirror_out_amt}</NumericLabel>
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                </div>
                                            </>
                                        :null
                                : '--'}
                        </TableCell>
                        <TableCell style={item?.status == false ?{color:'red'}:{}}>
                            {item.mirror_in_mirror_out_amt && item.mirror_in_mirror_out_payer == 'Lessee' ?
                                (simulationType === '1' || simulationType === '2') ?
                                    <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.mirror_in_mirror_out_amt}</NumericLabel>
                                    :
                                    simulationType === '3' ?
                                        item?.mirror_in_mirror_out_amt === simulatedItem.mirror_in_mirror_out_amt ?
                                            <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.mirror_in_mirror_out_amt}</NumericLabel>
                                            : <>
                                                <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{simulatedItem.mirror_in_mirror_out_amt}</NumericLabel>
                                                <div className="differentiation-values">
                                                    <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                                        <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item?.mirror_in_mirror_out_amt}</NumericLabel>
                                                        <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                                    </p>
                                                </div>
                                            </>
                                        :
                                        null
                                : '--'}
                        </TableCell>
                    </>
            }
        </TableRow>
    )
}
