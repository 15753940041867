import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

const LLPBreckdownGraph = ({ llpbreakdowndata, xaxis, lifeRemaining, color, maxLimit }) => {
    const maxArr = lifeRemaining.map((item,index) => maxLimit[index] - item )
    const [toggle, setToggle] = useState(true)
    let colorforMax = color == '#23E477' ? '#aaefc8' : color == '#0066FF'? '#a5c5f4' : '#ffe4b6'
    useEffect(() => {
        setToggle(false);
    },[]);
    var colors = [color];
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: `Shop visit - ${llpbreakdowndata?.name ? llpbreakdowndata?.name : '' }`,
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize: '16px'
            }
        },
        xAxis: {
            categories: xaxis
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                pointWidth: 25,
                depth: 40,
                dataLabels: {
                    enabled: false
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        colors: colors,
        tooltip: {
            formatter: function () {
                    let maxcap = this?.series?.userOptions?.tooltip && this?.series?.userOptions?.tooltip[this.point.index]
                    let tooltipObtained = this?.series?.userOptions?.tooltipForObtained ? this?.series?.userOptions?.tooltipForObtained[this.point.index] : this.x
                return '<b>' + tooltipObtained +
                '<br/>' + maxcap+ '</b>';
            }
          },
        legend: {
            enabled: false
        },
        series: [{
            name: 'maxLimit',
            data: maxArr,
            color: colorforMax,
            tooltipForObtained: maxArr.map( item => 'Cycles consumed: ' + item) ,
            tooltip: maxLimit.map(item => 'Cycle limit: '+item )
        },{
            name: 'llpdata',
            data: lifeRemaining,
            colorByPoint: true,
            tooltip:lifeRemaining.map(item => 'Cycles remaining: '+ item)
        }],
        credits: {
            enabled: false
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'downloadPDF'],
                },
            },
        }
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default LLPBreckdownGraph
