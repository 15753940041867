import React, { useState, useEffect } from "react";
import { Button, Grid, Paper, Table, TableBody } from "@material-ui/core";
import { FilterComponent, Pagination, PageLoader, EmptyCollection, DeletePopUp } from '../../../shared_elements'
import { STableLoader } from '../../../shared_elements/loaders';
import { Portfolio, PortfolioHeader, AddPortfolio } from '../components'
import { globalGetService, globalPostService, globalDeleteService, globalExportService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, onCheckPermission, removeEmptyKey } from '../../../utils';
import { portfolioFilters, addPortfolioObj } from '../'
import DeploymentMessage from "../../../shared_elements/components/DeploymentMessage";
const Portfolios = ({ }) => {
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false)
    const [filter, setFilter] = useState({});
    const [portfolioInfo, setPortfolioInfo] = useState({ list: [1, 1, 1, 1], pagination: { page: 1, per_page: 20, total: 100 } })
    const [addEditPortFolio, setAddEditPortFolio] = useState({ modal: false, mode: '', data: null })
    const [deletePortfolio, setDeletePortfolio] = useState({ modal: false, data: null })
    useEffect(() => {
        getPortfolios({ ...filter }, 'skeletonLoader');
    }, [])
    const getPortfolios = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`new-mr-calculator/fleet-portfolio-list/`, { ...query, module_type: 'portfolio' })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPortfolioInfo(response.data.data);
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const onExportReport = () => {
        setLoading(true);
        globalExportService(`new-mr-calculator/fleet-portfolio-list/`, { module_type: 'portfolio', download: 'xlsx' })
            .then(response => {
                downloadFileType(response.data, 'Portfolio_List_Export', 'xlsx');
                setLoading(false);
            })
    }
    const onDeletePortfolio = () => {
        setLoading(true);
        globalDeleteService(`new-mr-calculator/fleet-portfolio/`, { module_type: 'portfolio', id: [deletePortfolio.data.id] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeletePortfolio({ modal: false, data: null })
                    getPortfolios({ ...filter }, 'pageLoader');
                    setLoading(false);
                }
            })
    }
    let filterOptions = Object.assign({}, portfolioFilters);
    return (
        <section className='portfolio-fleet-section'>
            <DeploymentMessage />
            {skeletonLoader ? <STableLoader count={8} /> :
                <div>
                    {/* <Paper > */}
                    <Paper className='fleet-filter-div' square >
                        <Grid container spacing={1}>
                            <Grid item md={9} xs={3}>
                                <FilterComponent
                                    filter={filter}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getPortfolios({ ...applyFilter, page: 1, per_page: portfolioInfo.pagination.per_page }, 'pageLoader')}
                                />
                            </Grid>
                            <Grid item md={3} xs={9}>
                                <ul className="list-inline">
                                    {
                                        onCheckPermission('mr_calculator', 'portfolio', 'U') ?
                                            <li className="list-inline-item">
                                                <Button onClick={() => setAddEditPortFolio({ modal: true, mode: '', data: addPortfolioObj })} color="primary" size='small' variant="contained">
                                                    Add Portfolio
                                                </Button>
                                            </li> : null}
                                    <li className="list-inline-item">
                                        <Button color="primary" size='small' variant="outlined" onClick={() => onExportReport()}>
                                            Export
                                        </Button>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper style={{ maxHeight: window.innerHeight - 190 + 'px', overflow: "auto" }}>
                        <Table className='mui-styled-table' stickyHeader>
                            <PortfolioHeader />
                            <TableBody>
                                {portfolioInfo.list.map((item) =>
                                    <Portfolio
                                        item={item}
                                        onEdit={() => setAddEditPortFolio({ modal: true, mode: 'edit', data: item })}
                                        onDelete={() => setDeletePortfolio({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                    <Paper>
                        <Pagination
                            pagination={portfolioInfo.pagination}
                            onChangePage={(event, newPage) => getPortfolios({ page: newPage + 1, per_page: portfolioInfo.pagination.per_page }, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getPortfolios({ page: 1, per_page: event.target.value }, 'pageLoader')}
                        />
                        {portfolioInfo.list.length ? null : <EmptyCollection />}
                    </Paper>
                    {/* </Paper> */}
                </div>
            }
            {addEditPortFolio.modal ?
                <AddPortfolio
                    addEditPortFolio={addEditPortFolio}
                    getResponseBack={() => getPortfolios({ ...filter }, 'skeletonLoader')}
                    toggleModalFn={() => setAddEditPortFolio({ modal: false, mode: '', data: null })}
                /> : null
            }
            {deletePortfolio.modal ?
                <DeletePopUp
                    modal={deletePortfolio.modal}
                    toggleModalFn={() => setDeletePortfolio({ modal: false, data: null })}
                    title="Delete Portfolio"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeletePortfolio}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default Portfolios;