import Fleet from './pages/Fleet';
import PortfolioDetail from './pages/PortfolioDetail';
import Portfolios from './pages/Portfolios';
import PageLayout from '../../hocs/PageLayout'
import UserRoleAccess from '../../hocs/UserRoleAccess';
export const fleetRoutes = [
  {
    path: '/fleets',
    component: PageLayout(Fleet, {apps: 'Fleet', layoutPhase: 1}),
    key: 'Fleet',
    apps:'commVerge'
  },
  {
    path: '/portfolios',
    component: PageLayout(Portfolios, {apps: 'Portfolios', layoutPhase: 1}),
    key: 'Portfolios',
    apps:'commVerge'
  },
  {
    path: '/portfolio/:slug',
    component: PageLayout(PortfolioDetail, {apps: 'PortfolioDetail', layoutPhase: 1}),
    key: 'PortfolioDetail',
    apps:'commVerge'
  },
]
