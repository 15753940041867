export const fleetFilters = {
    serial_number:{
        inputType: 'text',
        title: 'Serial Number',
    },
    // asset_type:{
    //     inputType: 'dropdown',
    //     title: 'Aircraft Type',
    //     options:[],
    //     labelKey:'name',
    //     valueKey:'id'        
    // }
};
export const portfolioFilters = {
    name:{
        inputType: 'text',
        title: 'Name',
    },
    // asset_type:{
    //     inputType: 'dropdown',
    //     title: 'Aircraft Type',
    //     options:[],
    //     labelKey:'name',
    //     valueKey:'id'        
    // }
};
export const addAssemblyObj = {
    serial_number: '',
    asset_type: '',
    cycles: '',
    hours: '',
    date_of_manufacture: '',
    region_of_operation: '',
    location: null,
    maintenance_program: '',
    as_of_date: '',
    lsv_date: '',
    tsn_at_lsv: '',
    csn_at_lsv: '',
    status: '',
    asset_aircraft_type: '',
};
export const editAssemblyObj = {
    serial_number: '',
    asset_type: '',
    cycles: '',
    hours: '',
    date_of_manufacture: '',
    region_of_operation: '',
    location: null,
    maintenance_program: '',
    as_of_date: '',
    lsv_date: '',
    tsn_at_lsv: '',
    csn_at_lsv: '',
    status: '',
    asset_aircraft_type: '',
    nsv_date: '',
    sv_cost: ''
};
export const addPortfolioObj = {
    name:'',
    remarks: ''
}
export const fleetGraphHd =[
    {label:'Asset', id:'asset'},
    {label:'Serial No', id:'sr_no'},
    {label:'Event Date', id:'nsv_date'},
    {label:'Check Details', id:'check_details'},
    {label:'MR Rate', id:'mr_rate'},
    {label:'SV Cost', id:'sv_cost'},
    {label:'Fund Balance', id:'fund_balance'},
]
export const assetTypes =[
    {label:'Airframe', value:'airframe'},
    {label:'Engine', value:'engines'},
    {label:'LG', value:'lg'},
    {label:'APU', value:'apu'},
]