
import React from 'react';
import { Paper } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const EolLlpShopvisitGraph = ({data, callExternalFunction}) => {
    const xaxis =data?.llp_graph.map (item => moment(item?.sv_date).format('MMM DD, YYYY'))
    const fanLlpGraphData =data?.llp_graph.map (item => item?.llp_modules[0]?.life_remaining )
    const coreLlpGraphData = data?.llp_graph.map (item => item?.llp_modules[1]?.life_remaining )
    const lptLlpGraphData = data?.llp_graph.map (item => item?.llp_modules[2]?.life_remaining )
    const tooltip = data?.llp_graph.map (item => item?.llp_modules).map(item => item.map(child => child?.life_remaining_percentage))
    const options = {
        chart: {
            type: 'column',
        },
        title: {text: '',
        align: 'left',
        style: {
            fontFamily: 'Conv_IBMPlexSans-Regular',
            fontSize:'16px'
        }
        },
        xAxis: {
            categories: xaxis
        },
        yAxis: {
            title: {text: 'Cycle Limit'}         
        },
        
        tooltip: {
            formatter: function () {
                return '<b> Cycles remaining: ' + this.y+' </b> (' +this.series?.userOptions?.tooltip[this.point.index] + '%)<b>' + '</b>' ;

            }
          },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                // dataLabels: {  
                //     enabled: true
                // },
                point: {
                    events: {
                      click: function( item , index) {
                        callExternalFunction(data?.llp_graph.map(item => item?.llp_modules)[item.point.index].find(item => item?.name == this.series?.name), this.series.color, 'eol' );
                      }
                    }
                  }
            }
        },
        series: [
            {
                name: 'Fan',
                data: fanLlpGraphData,
                color:'#0066FF',
                tooltip: tooltip.map(item => item[0])
            }, 
            {
                name: 'Core',
                data: coreLlpGraphData,
                color:'#F9AC2A',
                tooltip: tooltip.map(item => item[1])
            }, 
            {
                name: 'LPT',
                data: lptLlpGraphData,
                color:'#23E477',
                tooltip: tooltip.map(item => item[2])
            }
        ],
        credits: {
            enabled: false
        },
    }
    return (
        <Paper>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </Paper>
    )
}
export default EolLlpShopvisitGraph

