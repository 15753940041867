import React from 'react'
import { Paper, Button, Dialog, TableCell, TableRow, DialogContent, DialogTitle, DialogActions, Grid, TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import { EmptyCollection, TableListComp } from '../../../shared_elements';

export default function GraphTableList({ graphList, setGraphList, exportGraphListFn }) {
    return (
        <div>
            <Dialog
                open={graphList?.modal}
                onClose={() => setGraphList({ modal: false })}
                className='console-management-modal'
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title" dividers={true}>
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        {graphList?.name} <span>
                            <Button size='small' variant='outlined' color='primary' onClick={() => {exportGraphListFn() }}> Export</Button>
                        </span>
                    </div>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <TableListComp
                        heads={graphList?.data?.columns.map(item => {return {label:item} })}
                        data={graphList?.data?.list_data.map(item =>
                            <TableRow >
                                <TableCell>{item?.x_data}</TableCell>
                                {item?.avg_life_on_wing ?
                                <TableCell>{item?.avg_life_on_wing}</TableCell> 
                                : null}
                                <TableCell>{item?.total_sv_cost}</TableCell>
                                <TableCell>{item?.total_mr_rate}</TableCell>

                            </TableRow>
                        )}
                        noRecord={graphList?.data?.list_data?.length ? null : <EmptyCollection title="No Data Found!" />}
                    />
                </DialogContent>
                <DialogActions style={{ padding: '8px 24px' }}>
                    <Button size='small' variant='contained' color='primary' onClick={() => setGraphList({ modal: false })}> Okay</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
