import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import React from 'react'

export default function CalanderViewCard({ item, calViewMode, getCalanderData, onCalanderItemClick }) {
    return (
        <Grid item xs={12} sm={12} style={calViewMode == 0 ?{}:  item?.data.length ?{cursor:'pointer'}:{}}>
            <Paper className='cal-view-block' style={{ borderRadius: "0px" }} onClick={()=> calViewMode == 0 ? null : item?.data.length ? getCalanderData({month_view:item?.date}): null}  >
                {
                    calViewMode == 0 ?
                        <div className='cal-card-date'>  {moment(item?.date).format('MMM-YYYY')}  </div>
                        :
                        <div className='cal-card-date'>  {item?.date}  </div>
                }
                <div className='cal-card-content'>
                    {
                        item?.data.length ?
                            item?.data?.map(item =>
                                <div className='cal-table-row' style={{cursor:'pointer'}} onClick={()=>calViewMode == 0 ? onCalanderItemClick({id:item?.id , asset:item?.asset}) : null } >
                                    {item?.serial_number} ({item?.check_details}) &nbsp;
                                    <span>
                                        @ <b>{item?.nsv_date ? moment(item?.nsv_date).format('MMM-DD') : item?.nsv_date}</b>
                                    </span>
                                </div>
                            ) :
                            'No Events Due'
                    }
                </div>
            </Paper>
        </Grid>
    )
}
