import React from 'react';
import { Paper } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const LLPLifeRemainingGraph = ({label, events, index, callExternalFunction, graphIndex}) => {
    const cat = Object.keys(events).map((event) => moment(events[event][index]?.llp_graph_data?.sv_date).format('MMM DD, YYYY') )
    const fanLlpGraphData = Object.keys(events).map((event) => events[event][index]?.llp_graph_data?.llp_modules[0]?.life_remaining).filter(item => item != undefined)
    const coreLlpGraphData = Object.keys(events).map((event) => events[event][index]?.llp_graph_data?.llp_modules[1]?.life_remaining).filter(item => item != undefined)
    const lptLlpGraphData = Object.keys(events).map((event) => events[event][index]?.llp_graph_data?.llp_modules[2]?.life_remaining).filter(item => item != undefined)
    const childGraphData =Object.keys(events).map((event) => events[event][index]?.llp_graph_data?.llp_modules).filter(item => item != undefined )
    const tooltip = Object.keys(events).map((event) => events[event][index]?.llp_graph_data?.llp_modules).map(item => item.map(child => child?.life_remaining_percentage))
    const options = {
        chart: {
            type: 'column',
        },
        title: {text: 'LLP Life Remaining at Shop visit',
        align: 'left',
        style: {
            fontFamily: 'Conv_IBMPlexSans-Regular',
            fontSize:'16px'
        }
        },
        xAxis: {
            categories: cat
        },
        yAxis: {
            title: {text: 'Cycle Limit'}         
        },
        tooltip: {
            formatter: function () {
                return '<b> Cycles remaining: ' + this.y+' </b> (' +this.series?.userOptions?.tooltip[this.point.index] + '%)<b>' + '</b>' ;
            }
          },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                // dataLabels: {
                //     enabled: true
                // },
                point: {
                    events: {
                      click: function() {
                        callExternalFunction(childGraphData[graphIndex].find(item => item.name == this.series.name), this.series.color );
                      }
                    }
                  }
            }
        },
        series: [
            {
                name: 'Fan',
                data: fanLlpGraphData,
                color:'#0066FF',
                tooltip: tooltip.map(item => item[0])
            }, 
            {
                name: 'Core',
                data: coreLlpGraphData,
                color:'#F9AC2A',
                tooltip: tooltip.map(item => item[1])
            }, 
            {
                name: 'LPT',
                data: lptLlpGraphData,
                color:'#23E477',
                tooltip: tooltip.map(item => item[2])
            }
        ],
        credits: {
            enabled: false
        },
    }
    return (
        <Paper>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </Paper>
    )
}
export default LLPLifeRemainingGraph

