import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
class GoogleLocationField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: this.props.location};
  }
  componentDidMount(){
    this.setState({address: this.props.location})
  }
  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
    .then(results => {
      return results[0];
    })
    .then(resultAddress => {
        this.setState({ address: resultAddress.formatted_address });
        this.props.onFieldChange('', 'location', resultAddress);
    })
    .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{position:'relative'}}>
            <TextField
                required={this.props.required}
                id="location"
                label={this.props.label ? this.props.label : 'Location'}
                fullWidth
                margin="normal"
                value={this.state.address}
                InputLabelProps={{shrink: true}}
                {...getInputProps({
                    placeholder: 'Search Places ...',
                   // className: 'location-search-input',
                })}
                variant='outlined'
            />
            <div className="autocomplete-dropdown-container" style={{position:'absolute', left:'0px', top:'65px', zIndex:'999', width:'100%'}}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
export default GoogleLocationField;