import React, { useState } from 'react'
import { Grid, Dialog, DialogContent, DialogTitle, TableCell, TableRow, DialogActions, Button, Paper } from '@material-ui/core'
import EOLAnalyticsGraph from './EOLAnalyticsGraph';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { TableListComp } from '../../../shared_elements';
import moment from 'moment';
import { getCurrencyFormat } from '../../../utils';
import NumericLabel from 'react-pretty-numbers';
import EolLlpShopvisitGraph from './EolLlpShopvisitGraph';
export default function EOLGraphs({ eol, callExternalFunction }) {
    const [modal, setModal] = useState(false)
    const [llpbreakdownmodal, setLlpbreakdownmodal] = useState({})
    return (
        <>
            <Grid container spacing={4}>
                {eol.eol_conditions.map(data =>
                    data?.component_name?.name.includes('LLP') ?
                        null :
                        <Grid item xs={12} md={6}>
                            <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                                <div className='events-bar-chart-title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h4>{data?.component_name?.name}</h4>
                                    {
                                        data?.monthly_cashflow?.length ?
                                            <span style={{ cursor: 'pointer', height: '18px' }} onClick={() => { setModal({ data: data, flag: true }) }} >
                                                <OpenInNewIcon color='primary' style={{ fontSize: '20px' }} />
                                            </span>
                                            : null
                                    }
                                </div>
                                <EOLAnalyticsGraph
                                    data={data}
                                />
                            </Paper>
                        </Grid>
                )}
                {eol.eol_conditions.map(data =>
                    data?.component_name?.name.includes('LLP') ?
                        <Grid item xs={12} md={6}>
                            <Paper className="events-bar-chart" style={{ borderRadius: 0 }}>
                              <div className='events-bar-chart-title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h4>{data?.component_name?.name} Life Remaining at Shop visit</h4>
                                </div>
                            <EolLlpShopvisitGraph data={data} callExternalFunction={callExternalFunction} />
                            </Paper>
                            </Grid>
                        : null
                )
                }
            </Grid>
            {
                modal.flag ?
                    <Dialog
                        maxWidth='lg'
                        open={true}
                        onClose={() => setModal({ data: {}, falg: false })}
                    >
                        <DialogTitle>Cashflow {modal.data?.component_name?.name}</DialogTitle>
                        <DialogContent dividers={true}>
                            {
                                modal.data?.monthly_cashflow?.length ?
                                    <TableListComp
                                        heads={[{ label: 'Period', id: 'period' }, { label: 'Credit($)', id: 'creditAmt' }, { label: 'Debit($)', id: 'debitAmt' }, { label: 'Balance($)', id: 'balanceAmt' }]}
                                        data={modal.data?.monthly_cashflow.map(item =>
                                            <TableRow>
                                                <TableCell>{item.month ? moment(item.month).format('MMM-YYYY') : '--'} </TableCell>
                                                <TableCell>{item.credit ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.credit}</NumericLabel> : '--'} </TableCell>
                                                <TableCell>{item.debit ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.debit}</NumericLabel> : '--'} </TableCell>
                                                <TableCell>{item.balance ? <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{item.balance}</NumericLabel> : '--'} </TableCell>
                                            </TableRow>

                                        )}
                                    />
                                    :
                                    <>No data Found</>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setModal({})} variant='contained' color='primary'>
                                Okay
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null
            }
        </>
    )
}
