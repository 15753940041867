import React, { useState } from 'react'
import { DialogTitle, TableRow, TableCell, Dialog, DialogContent, DialogActions, Button, Accordion } from '@material-ui/core';
import { PageLoader, TableListComp } from '../../../shared_elements';
import { globalExportService, globalPostExportService } from '../../../utils/globalApiServices';
import { downloadFileType } from '../../../utils';

export default function DetailedListAnalytics({ analyticsDetailedData, closeModal, callExternalFunction, graphType, eventData }) {
  let analyticsListDetailedData = analyticsDetailedData?.data
  const [pageLoader, setLoading] = useState(false)
  var titleName = ''
  const exportListFn = () => {
    let url = ''
    if (analyticsDetailedData?.type === 'cost') {
      url = `new-mr-calculator/analytics/${analyticsDetailedData?.tabIndex}/aircraft_cost/`
    }
    else if (analyticsDetailedData?.type === 'check') {
      url = `new-mr-calculator/analytics/${analyticsDetailedData?.tabIndex}/aircraft_checks/`
    }
    else if (analyticsDetailedData?.type === 'apu') {
      url = `new-mr-calculator/analytics/${analyticsDetailedData?.tabIndex}/apu_graph/`
    }
    else if (analyticsDetailedData?.type === 'lg') {
      url = `new-mr-calculator/analytics/${analyticsDetailedData?.tabIndex}/lg_graph/`
    }
    else if (analyticsDetailedData?.type === 'engine') {
      url = `new-mr-calculator/analytics/${analyticsDetailedData?.tabIndex}/engine_graph/`
    }
    setLoading(true)
    globalPostExportService(url, { ...analyticsDetailedData?.filter, download: true })
      .then(response => {
        setLoading(false)
        downloadFileType(response.data, (`${titleName} Export`), 'xlsx');
      })
  }
  if (analyticsDetailedData?.type === 'cost') {
    titleName = 'Aircraft Cost Graph'
  }
  else if (analyticsDetailedData?.type === 'check') {
    titleName = 'Aircraft Maintenance Check Graph'
  }
  else if (analyticsDetailedData?.type === 'apu') {
    titleName = 'APU Graph'
  }
  else if (analyticsDetailedData?.type === 'lg') {
    titleName = 'LG Graph'
  }
  else if (analyticsDetailedData?.type === 'engine') {
    titleName = 'Engine Graph'
  }
  return (
    <div>
      {
        pageLoader ? <PageLoader /> : null
      }
      <Dialog
        open='true'
        onClose={closeModal}
        maxWidth='lg'
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: "space-between" }}>
            {titleName}
            <span>
              <Button onClick={exportListFn} variant='outlined' color='primary' size='small' style={{ float: "right" }}>
                Export
              </Button>
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <TableListComp
            heads={analyticsListDetailedData?.columns.map(item => { return { label: item, value: item } })}
            data={
              Object.keys(analyticsListDetailedData?.list_data).map(index =>
                <TableRow style={{ cursor: 'pointer' }} onClick={() => window.open(`intermediate/${analyticsListDetailedData?.log_ids[index]}`, '_blank')}>
                  {
                    analyticsListDetailedData?.list_data[index].map((childItem, childIndex) =>
                      <TableCell>
                        {childItem}
                      </TableCell>
                    )
                  }
                </TableRow>
              )}
            pagination={analyticsListDetailedData.pagination}
            onChangePage={(event, newPage) => callExternalFunction(graphType, eventData, { page: newPage + 1, per_page: analyticsListDetailedData.pagination.per_page })}
            onChangeRowsPerPage={(event) => callExternalFunction(graphType, eventData, { page: 1, per_page: event.target.value },)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant='contained' color='primary' size='small' style={{ float: "right" }}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
