import React, { Fragment } from 'react';
import moment from 'moment';
import { Table, TableRow, TableCell, Tooltip, Paper } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const PrsvEngineGraph = ({events, eventsSimulated, simulationType}) => {
  let data = [];
  let newData = [];
  if(events){
    Object.keys(events).map( year => {
      let d = [];
      d.push(new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime());
      d.push(events[year]);
      data.push(d);
    });
  }
  if(simulationType == '3' && eventsSimulated){
    Object.keys(eventsSimulated).map( year => {
      let d = [];
      d.push(new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime());
      d.push(eventsSimulated[year]);
      newData.push(d);
    });
  }
  const options = {
    chart: {
      type: 'area',
      zoomType: 'x'
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Asset Life ( Year )'
      }
    },
    yAxis:{
      title: {
        text: 'Maintenance Reserve Fund in US$',
        style: {
              fontSize: '11px'
          }
      },
      gridLineDashStyle: 'longdash'
    },
    credits: {
      enabled: false
    },
    series:  [
      {
        showInLegend: true,
        name: 'Calculated Fund Accruals',
        data: data,
        color: '#f4d0e6',
        tooltip:{
          valuePrefix:'US$'
      }
      },
      {
        showInLegend: newData.length ?true :false,
        name: 'Simulated Fund Accruals',
        data: newData,
        color: '#BAB9D3',
        lineColor: '#150F78',
        tooltip:{
          valuePrefix:'US$'
      }
      },
    ],
  };
  return(
    <Paper>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </Paper>
  )
}
export default PrsvEngineGraph;
