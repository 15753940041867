import React, { Fragment } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { Table, TableRow, TableCell, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { isMobile, isTablet } from 'react-device-detect';
import { imgStoragePath, displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const EngineEventList = ({engine, shopVisit, simulateShopVisit, simulationType, onFieldChange, engineIndex, shopVisitIndex, financeError, updateErrorEngineField}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell className="engine-event-cell" colSpan={7}>
        <Table>
          { Object.keys(shopVisit).map((sv,index) => 
            <TableRow>
              <TableCell style={{width:'14.28%'}}>
                {engine.enable_mature_run ? `Mature Run ${index === 0 ? 'PRSV':'LLP'}` : shopVisit[sv].name}
              </TableCell>
              <TableCell style={{width:'14.28%'}}>
                { simulationType === '1' || simulationType === '2' ? <h6>{moment(shopVisit[sv].eventDate).format(displayDateFormatShort)}</h6>:
                  <Fragment>
                    { shopVisit[sv].eventDate === simulateShopVisit[sv].eventDate ?
                      <h6>{moment(shopVisit[sv].eventDate).format(displayDateFormatShort)}</h6> :
                      <div className="differentiation-values">
                        <h6>{ moment(simulateShopVisit[sv].eventDate).format(displayDateFormatShort) }</h6>
                        <p style={{color:'#ff0000', fontSize:'12px'}}>
                          { moment(shopVisit[sv].eventDate).format(displayDateFormatShort) }
                          <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                        </p>
                      </div>
                    }
                  </Fragment>
                }
              </TableCell>
              <TableCell style={{ width: '10%' }}>
                {
                  simulationType == '1' ? shopVisit[sv]?.rest_days ? shopVisit[sv]?.rest_days : '0' :
                    simulationType == '2' ?
                      <TextField
                        style={{ marginTop: '0px' }}
                        id={`rest_days`}
                        disabled={index ==1}
                        fullWidth
                        margin="normal"
                        value={shopVisit[sv].rest_days ? shopVisit[sv].rest_days : ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'rest_days', parseInt(e.target.value), 'engines', engineIndex, shopVisitIndex, sv) : e.preventDefault() }}
                      />
                      :
                      simulationType == '3' ?
                        simulateShopVisit[sv]?.rest_days ?
                          <>
                            {simulateShopVisit[sv].rest_days}
                            {shopVisit[sv].rest_days !== simulateShopVisit[sv].rest_days ?
                              <div className="differentiation-values">
                                <p style={{ color: '#ff0000', fontSize: '12px' }}>
                                  {shopVisit[sv].rest_days}
                                  <Tooltip title="Original Value" arrow><InfoIcon style={{ fontSize: '13px', position: 'relative', top: '2px', left: '2px' }} /></Tooltip>
                                </p>
                              </div> : null}
                          </>
                          : '0' : null
                }
              </TableCell>
              <TableCell style={{width:'14.28%'}}>
                { simulationType === '1' ? <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].mr}</NumericLabel></h6>:null}
                {
                  simulationType === "2" ?
                  <TextField
                    id="mr"
                    fullWidth
                    margin="none"
                    value={shopVisit[sv].mr ? shopVisit[sv].mr:''}
                    InputLabelProps={{shrink: true}}
                    error={financeError && financeError[sv] && financeError[sv].mr ? true : false}
                    helperText={financeError && financeError[sv] && financeError[sv].mr ? financeError[sv].mr  : '' }
                    onFocus={(e) => financeError && financeError[sv] && financeError[sv].mr ? updateErrorEngineField('mr','',engineIndex,shopVisitIndex,sv) : e.preventDefault()}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'mr', e.target.value, 'engines',  engineIndex, shopVisitIndex, sv): e.preventDefault()}}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].mr === simulateShopVisit[sv].mr ?
                      <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].mr}</NumericLabel></h6> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateShopVisit[sv].mr}</NumericLabel></h6>
                        <p style={{color:'#ff0000', fontSize:'12px'}}>
                          <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].mr}</NumericLabel>
                          <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                        </p>
                      </div>
                    }
                  </Fragment>:null
                }
              </TableCell>
              <TableCell style={{width:'14.28%'}}>
                { simulationType === '1'?
                  <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].eventCost}</NumericLabel></h6>:null
                }
                {  simulationType === '2' ?
                  <TextField
                    id="eventCost"
                    fullWidth
                    margin="none"
                    value={shopVisit[sv].eventCost ? shopVisit[sv].eventCost:0}
                    InputLabelProps={{shrink: true}}
                    error={financeError && financeError[sv] && financeError[sv].eventCost ? true : false}
                    helperText={financeError && financeError[sv] && financeError[sv].eventCost ? financeError[sv].eventCost : ''}
                    onFocus={(e) => financeError && financeError[sv] && financeError[sv].eventCost ?  updateErrorEngineField('eventCost', '',engineIndex, shopVisitIndex, sv) : e.preventDefault()}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'eventCost', e.target.value, 'engines',  engineIndex, shopVisitIndex, sv): e.preventDefault()}}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].eventCost === simulateShopVisit[sv].eventCost ?
                      <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].eventCost}</NumericLabel></h6> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateShopVisit[sv].eventCost}</NumericLabel></h6>
                        <p style={{color:'#ff0000', fontSize:'12px'}}>
                          <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].eventCost}</NumericLabel>
                          <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                        </p>
                      </div>
                    }
                  </Fragment>:null
                }
              </TableCell>
              <TableCell style={{width:'14.28%'}}>
                { simulationType === '1' || simulationType === '2' ?
                  <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].fundCollected}</NumericLabel></h6>:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].fundCollected === simulateShopVisit[sv].fundCollected ?
                      <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].fundCollected}</NumericLabel></h6> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateShopVisit[sv].fundCollected}</NumericLabel></h6>
                        <p style={{color:'#ff0000', fontSize:'12px'}}>
                          <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].fundCollected}</NumericLabel>
                          <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                        </p>
                      </div>
                    }
                  </Fragment>:null
                }
              </TableCell>
              <TableCell style={{width:'14.28%'}}>
                { simulationType === '1' || simulationType === '2' ?
                  <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].difference}</NumericLabel></h6>:null
                }
                { simulationType === '3' ?
                  <Fragment>
                    { shopVisit[sv].difference === simulateShopVisit[sv].difference ?
                      <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].difference}</NumericLabel></h6> :
                      <div className="differentiation-values">
                        <h6><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{simulateShopVisit[sv].difference}</NumericLabel></h6>
                        <p style={{color:'#ff0000', fontSize:'12px'}}>
                          <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{shopVisit[sv].difference}</NumericLabel>
                          <Tooltip title="Original Value" arrow><InfoIcon style={{fontSize:'13px', position:'relative', top:'2px', left:'2px'}} /></Tooltip>
                        </p>
                      </div>
                    }
                  </Fragment>:null
                }
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableCell>
    </TableRow>
  )
}
export default EngineEventList;
