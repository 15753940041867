import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
require('highcharts/modules/exporting')(Highcharts);

const EOLAnalyticsGraph = ({data}) => {
    const liferemaining= data?.life_on_wing_plot?.map(item => item.life_remaining  )
    const monthlyCashflow= data?.monthly_cashflow?.map(item => item.balance )
    const monthData = data?.life_on_wing_plot?.map(item => moment(item.month).format('MMM-YYYY')  )
    let yLabel = `Life Remaining (${data?.component_name?.value_type})`
    let maxLifeRemaining = Math.max(...liferemaining)
    let maxMrCashFlow = Math.max(...monthlyCashflow) + Math.min(...monthlyCashflow) > 0 ? Math.max(...monthlyCashflow) : Math.abs(Math.min(...monthlyCashflow)) 
    let minMrCashFlow = Math.max(...monthlyCashflow) + Math.min(...monthlyCashflow) > 0 ? -Math.max(...monthlyCashflow) : Math.min(...monthlyCashflow) 
    const options = {
        chart: {
            zoomType: 'xy',
        },
        plotOptions:{
            line:{
                marker:{
                    enabled : false
                }
            }
        },
        title: {text: ''},
        subtitle: {text: ''},
        credits:false,
        xAxis: [{
            categories: monthData,
            crosshair: true
        }],
        yAxis: [{ // Primary yAxis
            labels: {
                style: {
                    color: '#4f8fee'
                }
            },
            min: minMrCashFlow,
            max: maxMrCashFlow,
            title: {
                text: 'MR Collection (US$)',
                style: {
                    color: '#4f8fee'
                }
            },
            opposite: true
    
        }, { // Life Remaining yAxis
            gridLineWidth: 0,
            title: {
                text: yLabel,
                style: {
                    color: '#F9AC2A'
                }
            },
            min: -maxLifeRemaining,
            max: maxLifeRemaining,
            labels: {
                style: {
                    color: '#F9AC2A'
                }
            },
            // Adding a reference red color line 
            plotLines: [{
                color: 'green',      
                width: 2,          
                value: data?.value,      
                zIndex: 4,  
                axis: undefined    
            }],
        }],
        tooltip: {
            shared: true,
            formatter: function () {
                return '<b>' + moment(this.x).format('MMM-YYYY') +
                    '<br/></b style="color: #F9AC2A;">Life Remaining: <b>' + this.points[0].y + '</b>'+
                    '<br/></b style="color:rgb(79, 143, 238);"}}>MR Collection: <b>' + this.points[1].y + '</b>'+
                    '<br/></b style="color: green;"}>Expected Return Condition: <b>' + data?.value + '</b>';
            }
        },

        series: [{
            name: 'Life remaining',
            // type: 'spline',
            yAxis: 1,
            data: liferemaining,
            color:'#F9AC2A'
        },
         {
            name: 'MR Collection',
            // type: 'spline',
            data: monthlyCashflow,
            color:'#4f8fee',
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        floating: false,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },
                    yAxis: [{
                        labels: {
                            align: 'right',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    },
                     {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, 
                    {
                        visible: false
                    }]
                }
            }]
        }
    }
    return(
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default EOLAnalyticsGraph;